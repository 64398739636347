import React from "react";
import axios from 'axios';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
// import Favorite from "@material-ui/icons/Favorite";

// core components
import Header from "components/Header/Header.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Footer from "components/Footer/Footer.jsx";
import FooterLinks from "components/Footer/FooterLinks.jsx";

// import Button from "components/CustomButtons/Button.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SectionDescription from "./Sections/SectionDescription.jsx";
import SectionProperties from "./Sections/SectionProperties.jsx";
import SectionOffice from "./Sections/SectionOffice.jsx";
import SectionContact from "./Sections/SectionContact";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.jsx";

class PropertiesPage extends React.Component {


  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      stats: { hoteles: { count: 0, tags:[] },
               edificios: { count: 0, tags:[]},
               proyectos: { count: 0, tags:[]},
               suelos: { count: 0, tags:[]},
               naves: { count: 0, tags:[]},
               centros_comerciales: { count: 0, tags:[]},
               locales: { count: 0, tags:[]},
               oficinas: { count: 0, tags:[]},
               promociones: { count: 0, tags:[]},
               viviendas: { count: 0, tags:[]},
               fincas: { count: 0, tags:[]},
               operaciones_oferta: { count: 0, tags:[]},
               operaciones_demanda: { count: 0, tags:[]},
               noticias: { count: 0, tags:[]},
              }
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    let url = 'https://api.kinosgroup.com/api/v1/stats';

    axios
      .get(url)
      .then(response => {
            if (this._isMounted) {
            this.setState({ stats: response.data.data });
            console.log(response.data.data);
            }
        })
        .catch(errors => {
          console.error(errors);
        });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {
    const { classes } = this.props;
    const { stats } = this.state;
    return (
      <div>
        <Header
          brand="Kinos Corp"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "info"
          }}
        />
      <Parallax image={require("assets/img/Kinos/properties.jpg")} filter="dark" small>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <h1 className={classes.title}>Propiedades</h1>
                <h4>

                  Combinando nuestro conocimiento del mercado regional con experiencia en la industria, brindamos un servicio completo en todos los asuntos inmobiliarios para propietarios, ocupantes, instituciones financieras, desarrolladores e inversores.
                </h4>


              </GridItem>
            </GridContainer>

          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <SectionDescription />
            <SectionOffice />
            <SectionProperties stats={stats} id="more"/>
            <SectionContact />
          </div>
        </div>
        <Footer>
          <FooterLinks/>
        </Footer>
      </div>
    );
  }
}

export default withStyles(aboutUsStyle)(PropertiesPage);
