import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Info from "components/Typography/Info.jsx";

import brokerage from "assets/img/Kinos/Services/Thumbnails/brokerage.jpg";
import development from "assets/img/Kinos/Services/Thumbnails/development.jpg";
import valuation from "assets/img/Kinos/Services/Thumbnails/valuation.jpg";
import finance from "assets/img/Kinos/Services/Thumbnails/finance.jpg";
import development02 from "assets/img/Kinos/Services/Thumbnails/development02.jpg";
import assetsManagement from "assets/img/Kinos/Services/Thumbnails/assets-management.jpg";
import family_office from "assets/img/Kinos/Services/Thumbnails/family_office-bg.jpg";
import research from "assets/img/Kinos/Services/Thumbnails/research.jpg";
import investment from "assets/img/Kinos/Services/Thumbnails/investment.jpg";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.jsx";

function SectionInterested({ ...props }) {
  const { classes } = props;
  return (
    <div className={classes.section}>
      <h3 className={classes.title + " " + classes.textCenter}>
        Puede estar interesado en...
      </h3>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
              <Link to="/buying-selling" className={classes.dropdownLink}>
                <img src={brokerage} alt="BROKERAGE DE COMPRA/VENTA" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + brokerage + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>EMPRESA/PARTICULARES</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/buying-selling" className={classes.dropdownLink}>
                  INTERMEDIACIÓN COMPRA, VENTA Y ALQUILER
                </Link>
              </h4>
              <p className={classes.description}>
                Cuando se trata de comprar,  vender o alquilar  propiedades, asesoramos en todos los mercados tanto residenciales y comerciales...
                <Link to="/buying-selling" className={classes.dropdownLink}> Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <Link to="/new-developments" className={classes.dropdownLink}>
                <img src={development} alt="MARKETING Y VENTA DE OBRA NUEVA" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + development + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>PROMOTORES</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/new-developments" className={classes.dropdownLink}>
                  MARKETING Y VENTA DE OBRA NUEVA
                </Link>
              </h4>
              <p className={classes.description}>

Aporta un enfoque moderno al marketing y las ventas de nuevos desarrollos. Desde conversiones de alquiler boutique hasta lujosos condominios desde cero, explore nuestra cartera de ofertas actuales...
                <Link to="/new-developments" className={classes.dropdownLink}> Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <Link to="/assets-management" className={classes.dropdownLink}>
                <img src={assetsManagement} alt="assets management" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + assetsManagement + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>
                  BANCA / PARTICULARES
                </h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/assets-management" className={classes.dropdownLink}>
                  GESTION DE ACTIVOS
                </Link>
              </h4>
              <p className={classes.description}>

                Ayudamos a desarrollar una estrategia, crear un marco para la toma de decisiones en la tasación de la propiedad..
                <Link to="/assets-management" className={classes.dropdownLink}> Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
              <Link to="/family-office" className={classes.dropdownLink}>
                <img src={family_office} alt="..." />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + family_office + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>EMPRESAS FAMILIARES / PARTICULARES</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/family-office" className={classes.dropdownLink}>
                  FAMILY OFFICE / GESTIÓN DE PATRIMONIOS FAMILIARES
                </Link>
              </h4>
              <p className={classes.description}>

Una perspectiva objetiva a largo plazo sobre la inversión, estructuración y salvaguarda de activos, para preservar y hacer crecer la riqueza de nuestros clientes...
                <Link to="/family-office" className={classes.dropdownLink}> Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <Link to="/finance" className={classes.dropdownLink}>
                <img src={finance} alt="Finance" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + finance + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>INVERSORES</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/finance" className={classes.dropdownLink}>
                  FINANZAS
                </Link>
              </h4>
              <p className={classes.description}>
                Ya sea invirtiendo en una casa, una propiedad para alquilar o una cartera de activos, nuestros equipos dedicados cubren todas las áreas del asesoramiento financiero,
                ayudando a los clientes a tomar decisiones inteligentes basadas en conocimientos del mercado y del sector...
                <Link to="/finance" className={classes.dropdownLink}> Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <Link to="/investment-services" className={classes.dropdownLink}>
                <img src={investment} alt="..." />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + investment + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>
                  EMPRESAS / FAMILIAS
                </h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/investment-services" className={classes.dropdownLink}>
                  INVERSIONES
                </Link>
              </h4>
              <p className={classes.description}>

                INVERSORES, INVERSIONES, FUENTES DE NEGOCIOS
                Con especialistas y socios que trabajan en todos los sectores inmobiliarios, podemos asesorar a todo tipo de cliente sobre oportunidades de inversión en todos los rincones de España...
                <Link to="/investment-services" className={classes.dropdownLink}> Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
              <Link to="/valuation" className={classes.dropdownLink}>
                <img src={valuation} alt="Valuation" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + valuation + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>EMPRESAS / FONDOS / PASRTICULARES</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/valuation" className={classes.dropdownLink}>
                  VALORACIÓN
                </Link>
              </h4>
              <p className={classes.description}>
                Kinos proporciona valoraciones comerciales y residenciales
a todo tipo de clientes...
                <Link to="/valuation" className={classes.dropdownLink}> Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <Link to="/research" className={classes.dropdownLink}>
                <img src={research} alt="..." />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + research + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>EMPRESAS / PARTICULARES / FONDOS</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/research" className={classes.dropdownLink}>
                  BÚSQUEDA Y PROSPECCIÓN
                </Link>
              </h4>
              <p className={classes.description}>
                Kinos proporciona asesoramiento y análisis a clientes en toda España, con especialistas en investigación inmobiliaria comercial, residencial y mundial...

                <Link to="/research" className={classes.dropdownLink}> Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <Link to="/development" className={classes.dropdownLink}>
                <img src={development02} alt="development" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + development02 + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>
                  PROMOTORES / ADMINISTRACION PÚBLICA / INVERSORES
                </h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/development" className={classes.dropdownLink}>
                  DESARROLLO URBANÍSTICO
                </Link>
              </h4>
              <p className={classes.description}>
                Desde la valoración, compraventa de terrenos y adquisiciones hasta la financiación y soporte de cartera, trabajamos en todo el proceso...
                <Link to="/development" className={classes.dropdownLink}> Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(sectionInterestedStyle)(SectionInterested);
