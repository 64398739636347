import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
// nodejs library that concatenates classes
import classNames from "classnames";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import FooterLinks from "components/Footer/FooterLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import SectionPills from "./Sections/SectionPills.jsx";
import SectionProduct from "./Sections/SectionProduct.jsx";
import RegisterForm from "./Sections/RegisterForm.jsx";

import SubscribeLine from "./Sections/SubscribeLine.jsx";
import SectionDescription from "./Sections/SectionDescription.jsx";
import SectionServices from "./Sections/SectionServices.jsx"
import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.jsx";

class RegisterPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          brand="Kinos Corp"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "info"
          }}
        />
        <Parallax image={require("assets/img/Kinos/register.jpeg")} filter="dark" small>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <h1 className={classes.title}>Regístrese con nosotros</h1>
                <h4 className={classes.title}>
                  DESCUBRA CONFIDENCIALMENTE OFERTAS DE INVERSIÓN, FUSIONES Y ADQUISICIONES INMOBILIARIAS.
                </h4>
                <RegisterForm />
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classes.main}>
          <SectionProduct />
          <SectionDescription />
          <SectionServices />
          <div className={classes.container}>
            <SectionPills />
          </div>

          <SubscribeLine />
        </div>
        <Footer>
          <FooterLinks/>
        </Footer>
      </div>
    );
  }
}

export default withStyles(blogPostsPageStyle)(RegisterPage);
