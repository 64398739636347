import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
// core components

import SectionDestacadas from "KComponents/SectionDestacadas.jsx";
import SectionRecents from "KComponents/SectionRecents.jsx";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";

function SectionText({ ...props }) {
  const { classes } = props;
  const { featured } = props;
  const { recents } = props;

  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            Kinos brinda un amplio espectro de asesoría técnica sobre oportunidades de inversión,
          </h3>
          <p>
            Kinos es una gran fuente de captación de oportunidades de inversión y de negocio así como un gran marketplace para encontrar inversores.
          </p>
          <p>
            Cubrimos todos los aspectos de la evaluación, análisis, gestión de documentación y validación de oportunidades de inversión e igualmente validamos la solvencia
            y capacidad de inversión de cada demanda abierta por inversores.
          </p>
          <p>
            Trabajamos en estrecha colaboración con nuestros clientes a lo largo de cada operación de demanda de búsqueda de oportunidades de inversión,
            escuchando lo que desea lograr y encontrando con frecuencia formas nuevas o innovadoras de cumplir con los objetivos.
            Para asignaciones complejas, creamos y lideramos equipos de proyectos efectivos, trabajando en estrecha colaboración con red de clientes inversores oferentes
            en proceso de des-inversión  y con una amplia red de consultores externos.
            Esta combinación de habilidad de base amplia con la riqueza de nuestra experiencia garantiza que siempre vean un amplio número de ofertas
            con un imagen completa del mercado para poder tomar decisiones correctas.
          </p>
          <h3>
            Desarrollo de negocios & Asesoramiento Estratégico
          </h3>
          <p>
            Podemos asistir en el análisis de oportunidades de inversión para cualquier cartera inmobiliaria. Dirigimos red amplia de colaboradores y profesionales
            brindado asesoramiento estratégico en una panel de gestión unificado. Podemos ser su unidad de desarrollo de proyectos desde el plan de inversión inicial
            a la etapa de venta final y retorno de capital más beneficios.
          </p>
          <p>
            Objetivos de seguridad y rentabilidad. Asesoramiento rápido y sólido. Estos factores son esenciales para los procesos de toma de decisiones para transacciones de compra y venta,
            prestación de servicios de contratos de arrendamiento, cuestiones de sostenibilidad y optimización de las características de una propiedad como desarrollo de la inversión patrimonial.
          </p>
          <Quote
            textClassName={classes.quoteText}
            text="“¡La excelencia es nuestro objetivo, la satisfacción de los clientes es nuestro orgullo!”"
            author="Joaquín Morales, CEO."
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={10} className={classes.section}>
          <SectionDestacadas featured= {featured} url="investment" />
          <SectionRecents recents= {recents} url="investment" />
        </GridItem>

        <GridItem xs={12} sm={8} md={8} id="search" >
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
