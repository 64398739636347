import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description}>
            SERVICIO DE CONSULTORÍA EN GESTIÓN DE ACTIVOS
          </h2>
          <h3>QUÉ HACEMOS</h3>
          <h5>Nuestro servicio de consultoría en gestión de activos ayuda a los clientes a desarrollar estrategias, crear un marco para la toma de decisiones y proporcionar información para las tasaciones de propiedades.
             El área de Asset Management centra su atención tanto en la optimización de las operaciones diarias como en la maximización de los ingresos con el fin de optimizar el valor de los inmuebles.
              El grado de diálogo alcanza tres niveles: Activo y Corporación, Instituciones Financieras e Inversores.

Optimice los costos y mejore e innove continuamente sus servicios de administración de propiedades y activos a través de la mejora de procesos, la implementación de plataformas y sistemas para aumentar la eficiencia y los cambios en los modelos operativos.
Gestione la propiedad y la cartera. Exija más de su propiedad inmobiliaria. Vaya más allá de la mitigación de costos y riesgos.
Si tiene una variedad de propiedades y activos en diferentes sectores, podemos ayudarlo a aprovechar al máximo su inversión.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
