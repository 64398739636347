import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.jsx";

class SectionProduct extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>
Líder en servicios inmobiliarios y gestión de inversiones.</h2>
            <h5 className={classes.description}>
              Kinos proporciona resultados excepcionales para los clientes al combinar conocimientos del mercado local, amplios servicios, experiencia especializada y herramientas y recursos tecnológicos de primer nivel.
              Brindamos asesoramiento y servicios de expertos para maximizar el valor de la propiedad para los ocupantes, propietarios e inversores de bienes raíces.
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Proceso de selección"
                description="Todos los miembros son examinados por instituciones financieras y todas las ofertas se revisan antes de publicarlas."
                icon={VerifiedUser}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Confidencialidad"
                description="Acceda a acuerdos comerciales y explore oportunidades de forma anónima."
                icon={Fingerprint}
                iconColor="success"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Conexión directa"
                description="Cuando esté listo, hable con el principal responsable de la toma de decisiones detrás de cada acuerdo comercial."
                icon={Chat}
                iconColor="danger"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(SectionProduct);
