import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { Link } from "react-router-dom";

// @material-ui/icons
import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import Business from "@material-ui/icons/Business";
import AccountBalance from "@material-ui/icons/AccountBalance";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
// import NavPills from "components/NavPills/NavPills.jsx";
// import Success from "components/Typography/Success.jsx";
// import Danger from "components/Typography/Danger.jsx";

import pricingStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/pricingStyle.jsx";

import bg11 from "assets/img/bg11.jpg";


function SectionPricing({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      {/* Pricing 1 START */}
      <div
        className={`${classes.pricing} ${classes.pricing1} ${classes.section}`}
        style={{ backgroundImage: `url(${bg11})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={`${classes.mlAuto} ${classes.mrAuto} ${
                classes.textCenter
              }`}
            >
              <h2 className={classes.title}>Empresas, inversores y desarrolladores</h2>
              <h5 className={classes.description}>
                Servicios a inversores internacionales
              </h5>
              <div className={classes.sectionSpace} />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card pricing plain>
                <CardBody pricing plain>
                  <h6
                    className={`${classes.cardCategory} ${
                      classes.marginBottom20
                    }`}
                  >
                    HONESTIDAD
                  </h6>
                  <div className={classes.icon}>
                    <Weekend className={classes.iconWhite} />
                  </div>
                  <h3
                    className={`${classes.cardTitleWhite} ${
                      classes.marginTop30
                    }`}
                  >
                    Brokers
                  </h3>
                  <p className={classes.cardCategory}>
                    RECONOZCA Y MEJORE LOS VALORES INMOBILIARIOS

                  </p>
                  <Link to="/brokers">
                    <Button round color="white">
                      Ver más...
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card pricing plain>
                <CardBody pricing plain>
                  <h6
                    className={`${classes.cardDescription} ${
                      classes.marginBottom20
                    }`}
                  >
                    CONFIANZA
                  </h6>
                  <div className={classes.icon}>
                    <Home className={classes.iconWhite} />
                  </div>
                  <h3 className={`${classes.cardTitleWhite} ${classes.marginTop30}`}>
                    Inversores
                  </h3>
                  <p className={classes.cardDescription}>
                    OFERTA DE NEGOCIOS / GENERACIÓN DE NEGOCIOS
                  </p>
                  <Link to="/investors">
                    <Button round color="white">
                      Ver más...
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card pricing plain>
                <CardBody pricing plain>
                  <h6
                    className={`${classes.cardCategory} ${
                      classes.marginBottom20
                    }`}
                  >
                    INTEGRIDAD
                  </h6>
                  <div className={classes.icon}>
                    <Business className={classes.iconWhite} />
                  </div>
                  <h3
                    className={`${classes.cardTitleWhite} ${
                      classes.marginTop30
                    }`}
                  >
                    Promotores
                  </h3>
                  <p className={classes.cardCategory}>
                    VISON INTELIGENTE DEL SECTOR INMOBILIARIO
                  </p>
                  <Link to="/developers">
                    <Button round color="white">
                      Ver más...
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card pricing plain>
                <CardBody pricing plain>
                  <h6
                    className={`${classes.cardCategory} ${
                      classes.marginBottom20
                    }`}
                  >
                    EXCELENCIA
                  </h6>
                  <div className={classes.icon}>
                    <AccountBalance className={classes.iconWhite} />
                  </div>
                  <h3
                    className={`${classes.cardTitleWhite} ${
                      classes.marginTop30
                    }`}
                  >
                    Network
                  </h3>
                  <p className={classes.cardCategory}>
                    INTEGRIDAD, CALIDAD, VALOR
                  </p>
                  <Link to="/relations">
                    <Button round color="white">
                      Ver más...
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Pricing 1 END */}


    </div>
  );
}

export default withStyles(pricingStyle)(SectionPricing);
