import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Bullet from "@material-ui/icons/DragIndicator";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.jsx";

function SectionServices(props) {
  const { classes } = props;
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>¿Qué ofrecemos?</h2>
          <h5 className={classes.description}>
            La cultura de asociación de Kinos con los mejores expertos, nuestro compromiso con la excelencia y la integridad intransigente nos convierte en una excelente opción para inversores con una visión a largo plazo.
          </h5>

          <h5 className={classes.description}>
            El interés de nuestro cliente es el principal núcleo de nuestra filosofía de gestión de activos. También adaptamos sus estrategias de inversión a las necesidades específicas de  cada cliente.
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="SERVICIOS DE ASESORÍA Y TRANSACCIONES"
            description={
              <span>
                <p>
                  Le ayudamos a obtener los resultados deseados con un enfoque deliberado y con visión de futuro para la planificación, ejecución e integración de cada operación. Existe más presión que nunca para garantizar que los acuerdos generen resultados duraderos para las partes interesadas. En Kinos, vemos las transacciones a través de la lente de un inversor y nos centramos en ayudar a los clientes a identificar, evaluar e implementar con éxito sus estrategias de crecimiento en el complejo panorama empresarial actual.
                </p>
              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="GESTIÓN DE ACTIVOS"
            description={
              <span>
                <p>
                  En Kinos ayudamos a los propietarios a mejorar la rentabilidad que generan sus propiedades. ¿Cómo?
                  Definiendo la mejor estrategia y reposicionando el activo para atraer a los mejores inquilinos y compradores.
                  Le acompañamos en cada paso del camino desde el análisis de compra hasta el final de la desinversión.
                </p>
              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="RELACIONES"
            description={
              <span>
                <p>
                  Contamos con una fuerte presencia local, nacional e internacional amplia  red empresarial con la plataforma de colaboración Torus.
                  Esto es fundamental. Las relaciones con líderes empresariales locales, banqueros, auditores, asesores financieros y aquellos involucrados
                  en la gestión de la cadena de suministro son fuentes importantes de flujo de transacciones. Las redes, las relaciones y
                  las asociaciones con otros profesionales son esenciales para desarrollar una sólida cartera de inversiones.
                </p>
              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="VENTA DE PROPIEDADES"
            description={
              <span>
                <p>
                  Para obtener ofertas, los activos deben comercializarse de manera efectiva y presentar su propuesta de valor única para inversores potenciales y
                  tener otra estrategia reflexiva para identificar inversiones futuras. 
                  Igualmente importante es un proceso bien establecido para evaluar las inversiones potenciales de manera exhaustiva y eficiente.
                </p>
              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>

        <GridItem md={6} sm={6}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >
          <h3 className={classes.title}>Estrategias de inversión</h3>
            <span>
              <p>
                <h5>Gestión de JV o Proceso de Desarrollo</h5>
                <h5>Modelado operativo financiero</h5>
                <h5>Presupuesto y control</h5>
                <h5>Estructura y especificaciones legales</h5>
                <h5>Interacción habilitada por la tecnología</h5>
                <h5>Informe financiero</h5>
                <h5>Informe operativo</h5>
                <h5>Estrategia de Marketing</h5>
                <h5>Estratgias de venta</h5>
                <h5>Análisis de costes del ciclo de vida</h5>
                <h5>Posicionamiento de activos</h5>
                <h5>Estrategias de salida</h5>
              </p>

            </span>
        </GridItem>

        <GridItem md={6} sm={6}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >
          <h3 className={classes.title}>Canales de inversión</h3>
            <span>

                <h6>Inversiones directas</h6>
                <h5>En activos inmobiliarios </h5>
                <h5>En proyectos</h5> 
                <h6>A través de intermediarios</h6>
                <h5>Gestión de inversiones </h5>
                <h5>Fondos de capital privado </h5>
                <h5>Fondos de infraestructura</h5> 
                <h5>Socimis</h5> 
                <h5>Reits</h5> 
                <h6>Otros vehículos de inversión conjunta...</h6>

            </span>
        </GridItem>

      </GridContainer>
    </div>
  );
}


export default withStyles(servicesStyle)(SectionServices);
