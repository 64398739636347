import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// import Separator from "@material-ui/icons/LinearScale";
import Bullet from "@material-ui/icons/DragIndicator";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.jsx";

function SectionServices(props) {
  const { classes } = props;
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>SERVICIOS PRINCIPALES, SOLO PARA CLIENTES GESTIONADOS EN COMERCIALIZACIÓN EXCLUSIVA</h2>



        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="Consultoría"
            description={
              <span>
                <p>
                  <ul>
                  <li>INTELIGENCIA DE MERCADO</li>
                  <li>DISEÑO DE PRODUCTO</li>
                  <li>ANÁLISIS DE PRECIOS</li>
                  </ul>
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="Marketing de proyectos"
            description={
              <span>
                <p>
                  <ul>
                  <li>MARCA Y POSICIONAMIENTO</li>
                  <li>ESTRATEGIA DE LANZAMIENTO</li>
                  <li>EJECUCIÓN DE MARKETING</li>
                  </ul>
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>

        <GridItem md={4} sm={4}>
          <InfoArea
            title="Comunicaciones"
            description={
              <span>
                <p>
                  <ul>
                  <li>PLANIFICACIÓN Y COMPRA DE MEDIOS</li>
                  <li>EVENTO PLANEADO</li>
                  </ul>
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>



        <GridItem md={4} sm={4}>
          <InfoArea
            title="Ventas"
            description={
              <span>
                <p>
                  <ul>
                  <li>ESTRATEGIA DE VENTAS</li>
                  <li>ENTRENAMIENTO DE VENTAS</li>
                  <li>GESTIÓN DE VENTAS</li>
                  </ul>
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>

        <GridItem md={4} sm={4}>
          <InfoArea
            title="Arrendamiento"
            description={
              <span>
                <p>
                  <ul>
                  <li>ANALÍTICA Y DESARROLLO DE PRODUCTOS</li>
                  <li>ESTRATEGIA Y EJECUCIÓN DE MARKETING</li>
                  <li>ESTRATEGIA Y GESTIÓN DE ARRENDAMIENTO</li>
                  </ul>
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>

        <GridItem md={4} sm={4}>
          <InfoArea
            title="Atención al cliente"
            description={
              <span>
                <p>
                  <ul>
                  <li>GESTIÓN DE CONTRATOS</li>
                  <li>COMUNICACIÓN CLIENTE</li>
                  <li>SERVICIOS DE FINALIZACIÓN DE REPASOS OBRA</li>
                  </ul>
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(servicesStyle)(SectionServices);
