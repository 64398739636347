import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Separator from "@material-ui/icons/LinearScale";
import Bullet from "@material-ui/icons/DragIndicator";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.jsx";

function SectionServices(props) {
  const { classes } = props;
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>¿Qué ofrecemos?</h2>
          <h5 className={classes.description}>

          </h5>

          <h5 className={classes.description}></h5>

        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="Obtenga toda la información que necesita en un solo lugar."
            description={
              <span>
                <p>
                  Optimice sus procesos de inversión, promoción y valoración de activos con Research Intelligence. Con Kinos puedes acceder a la plataforma con toda la información de tus activos.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>

        <GridItem md={6} sm={6}>
          <InfoArea
            title="Automatice el análisis, la valoración y la previsión inmobiliaria, aprovechando el big data y el aprendizaje automático."
            description={
              <span>
                  Automatice el análisis, la valoración y la previsión inmobiliaria, aprovechando el big data y el aprendizaje automático.
                  Muestre el valor de sus activos. Realice un análisis de mercado completo y analice las tendencias de compra para determinar una valoración precisa de sus activos.
              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>

        <GridItem md={6} sm={6}>
          <InfoArea
            title="Anticipe la dinámica del mercado y la evolución urbana."
            description={
              <span>
                <p>
                  Impulse su promoción inmobiliaria. Comprenda qué activos desarrollar en cada lugar, identifique a los clientes objetivo y
                  realice promociones optimizadas para cada cliente potencial.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="Identifique oportunidades de inversión de alto potencial."
            description={
              <span>
                <p>
                  Detecte oportunidades de inversión: Descubra dónde comprar o vender sus activos, encontrar mercados emergentes y analizar sus activos inmobiliarios en cuestión de segundos.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>

        <GridItem md={6} sm={6}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >
          <h3 className={classes.title}>Estrategias de inversión</h3>
            <span>
              <p>
                <h5>Gestión de JV o Proceso de Desarrollo</h5>
                <h5>Modelado operativo financiero</h5>
                <h5>Presupuesto y control</h5>
                <h5>Estructura y especificaciones legales</h5>
                <h5>Interacción habilitada por la tecnología</h5>
                <h5>Informe financiero</h5>
                <h5>Informe operativo</h5>
                <h5>Estrategia de Marketing</h5>
                <h5>Estratgias de venta</h5>
                <h5>Análisis de costes del ciclo de vida</h5>
                <h5>Posicionamiento de activos</h5>
                <h5>Estrategias de salida</h5>
              </p>

            </span>
        </GridItem>

        <GridItem md={6} sm={6}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >
          <h3 className={classes.title}>Canales de inversión</h3>
            <span>

                <h6>Inversiones directas</h6>
                <h5>En activos inmobiliarios </h5>
                <h5>En proyectos</h5> 
                <h6>A través de intermediarios</h6>
                <h5>Gestión de inversiones </h5>
                <h5>Fondos de capital privado </h5>
                <h5>Fondos de infraestructura</h5> 
                <h5>Socimis</h5> 
                <h5>Reits</h5> 
                <h6>Otros vehículos de inversión conjunta...</h6>

            </span>
        </GridItem>

      </GridContainer>
    </div>
  );
}



export default withStyles(servicesStyle)(SectionServices);
