import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
// core components

import SectionDestacadas from "KComponents/SectionDestacadas.jsx";
import SectionRecents from "KComponents/SectionRecents.jsx";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";

function SectionText({ ...props }) {
  const { classes } = props;
  const { featured } = props;
  const { recents } = props;
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            Ya sea que esté buscando comprar, vender, alquilar o alquilar, nuestros especialistas residenciales están con usted en cada paso del camino, asegurando un proceso fluido y una comunicación útil en cada etapa.
          </h3>
          <p>
            El equipo de Servicios Residenciales de Kinos International es uno de los principales asesores inmobiliarios privados, especialmente en el segmento de lujo, gracias a sus muchos años de experiencia y conocimiento profundo del mercado.
¿Quiere comprar, vender o desarrollar una propiedad residencial?
Kinos ofrece un asesoramiento integral en la venta o alquiler de inmuebles tanto a propietarios institucionales como a clientes privados desde la fijación de precios hasta la documentación necesaria. Creamos conceptos de marketing, análisis de mercado y planes publicitarios. También asesoramos en diseño de planos de planta y calidad de equipamiento en el contexto de proyectos inmobiliarios.
Kinos le brindará nuestro apoyo integral e individualizado durante toda la duración de la búsqueda de la propiedad de sus sueños y, cuando la hayamos encontrado, lo ayudará a negociar los contratos de compra o alquiler para asegurarse de que su sueño esté protegido.
            <br />
            <br />
              Ya sea que esté buscando comprar, vender, alquilar o alquilar, nuestros especialistas residenciales están con usted en cada paso del camino, asegurando un proceso fluido y una comunicación útil en cada etapa.

  Nuestro consejo es siempre honesto y claro. A través de cada negociación, compartimos nuestra experiencia y recursos con todas las partes, respondiendo preguntas y vinculándonos con los abogados de principio a fin.

  Nuestros equipos residenciales especializados también pueden ayudar, con una amplia experiencia en una variedad de industrias y áreas, desde casas de campo y ecuestres, hasta propiedades frente al mar y nuevos desarrollos.
          </p>
          <Quote
            textClassName={classes.quoteText}
            text="“¡La excelencia es nuestro objetivo, la satisfacción de los clientes es nuestro orgullo!”"
            author="Joaquín Morales, CEO."
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={10} className={classes.section}>
          <SectionDestacadas featured={featured}  url="residential" />
          <SectionRecents recents={recents}  url="residential" />
        </GridItem>

        <GridItem xs={12} sm={8} md={8} id="search" >
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
