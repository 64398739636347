import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description, classes.textCenter}>
            NUESTRO ENFOQUE ESTÁ EN LAS NECESIDADES Y PREFERENCIAS DE INVERSIÓN DE NUESTROS CLIENTES.
          </h2>
          <h3>SERVIVIOS PARA INVERSORES</h3>
          <h5>
            Constantemente hemos obtenido resultados atractivos, a pesar de las condiciones cambiantes del mercado y a lo largo de los ciclos del mercado. 
            Ofrecemos una sólida gama de servicios para inversores diseñados para impulsar el rendimiento de sus inversiones inmobiliarias y el valor de los activos.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
