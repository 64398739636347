import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description, classes.textCenter}>
            SERVICIOS DE INVERSIONES PARA INVERSORES INSTITUCIONALES
          </h2>
          <h3>Kinos ha construido una valiosa red de relaciones con fondos soberanos, fondos de pensiones e instituciones globales.
            Estas relaciones son cercanas y significativas, construidas sobre una base de confianza y respeto mutuo.</h3>
          <h5>Gestionamos activos institucionales para una amplia gama de clientes, incluidas compañías de seguros, fondos de pensiones, bancos, organizaciones benéficas y oficinas familiares. Somos una firma de inversión líder a nivel mundial que ofrece servicios innovadores de gestión de carteras a inversores institucionales y privados.
            Durante más de 15 años, nos hemos centrado en descubrir nuevas formas de agregar valor a largo plazo a las carteras de nuestros clientes en función de sus objetivos únicos.
          </h5>
          <h5>
            Hemos construido una red global (Torus Network) de destacados asesores de inversiones y una amplia plataforma de investigación para apoyarlos.
            Con más de 700 socios en todo el mundo, incluido personal de inversión e investigación en cuatro continentes, buscamos constantemente descubrir las mejores ideas para las carteras de nuestros clientes.
          </h5>
          <h5>
            Tenemos socios en todo el mundo, incluidos España, Europa, EMEA, América Latina, Asia, así como Beijing, Londres, Singapur y Sydney.
          </h5>
          <h5>
            Seleccionamos a nuestros colegas con gran atención a su potencial para convertirse en un miembro valioso de un equipo colegiado, inteligente y trabajador.
          </h5>
          <h5>
            Nuestros clientes confían en la experiencia, el liderazgo y la integridad de nuestros profesionales de inversiones. Al trabajar con algunos de los inversores institucionales más sofisticados del mundo, aportamos un profundo conocimiento de las mejores prácticas de gestión de carteras a los clientes a los que servimos.
          </h5>
          <h5>
            Nuestro compromiso con la independencia ayuda a garantizar que el asesoramiento que brindamos a nuestros clientes se base únicamente en lo que es mejor para sus carteras.
          </h5>

          

        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
