import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
// core components

import SectionDestacadas from "./SectionDestacadas.jsx";
import SectionRecents from "./SectionRecents.jsx";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";

function SectionText({ ...props }) {
  const { classes } = props;
  const { featured } = props;
  const { recents } = props;

  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            Marketplace para inversores
          </h3>
          <p>
            Kinos proporciona un amplio  servicio de asesoría sobre operaciones de venta, des-inversión, realizamos plan estratégico de venta y comercialización de activos comerciales y residenciales.
          </p>
          <p>
             Cubrimos todos los aspectos de operaciones de venta, la evaluación, planificación, asignación de precios,  desarrollo plan de contactos, revisión de ocupación, mantenimiento y adecuación de activos inmobiliarios de todo tipo.
          </p><p>
Trabajamos en estrecha colaboración con nuestros clientes a lo largo de cada operación de venta, escuchando lo que desea lograr y
encontrando con frecuencia formas nuevas o innovadoras de cumplir con el plan de venta. Para asignaciones complejas, creamos y lideramos equipos de proyectos efectivos,
trabajando en estrecha colaboración con nuestros una red de profesionales locales, nacionales e internacionales, integrados en Torus Network.
Esta combinación de habilidad e innovación tecnológica amplia exponencialmente la red de contactos y relaciones con inversores de por exponencial,
ampliando  la riqueza de nuestra experiencia.
Con la gran de contactos y relaciones con los mejores profesionales  garantizamos que siempre vea la imagen completa de mercado y pueda tomar decisiones completamente informadas en tiempo,
forma y valor óptimo para la venta de activos o negocios.
</p><p>
Desarrollo de negocio y asesoramiento estratégico de operaciones de ventas para obtener retornos de beneficios adecuados a los objetivos fijados
</p><p>
Le ayudamos  en el análisis de mercado de cualquier cartera inmobiliaria. Brindamos asesoramiento estratégico para le elección del momento óptimo de venta a los precios más adecuados para maximizar resultados en el plazo fijado. Podemos ser su unidad de desarrollo externa,  realizamos el proceso integral para la  venta activos, desde activos individuales a grandes carteras o portfolios así como  proyectos de obra nueva  completos,  desde el concepto hasta la entrega final a los clientes.

</p><p>
Obtención de objetivos y resultados es la prioridad. Asesoramiento rápido y sólido. Estos factores son esenciales para los procesos de toma de decisiones para lograr transacciones de compra y venta equilibradas para todas las partes. Disponemos de la más grande red de profesionales ( abogados, economistas, auditores, profesionales de banca, financieros, arquitectos, brokers, agentes inmobiliarios, agentes de seguros, gestores de carteras, directivos, analistas de riesgos, gestores de fondos de inversión, red de servicers etc ) para el logro de los objetivos de venta marcados.
          </p>
          <Quote
            textClassName={classes.quoteText}
            text="“¡La excelencia es nuestro objetivo, la satisfacción de los clientes es nuestro orgullo!”"
            author="Joaquín Morales, CEO."
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={10} className={classes.section}>
          <SectionDestacadas featured= {featured} url="offer" />
          <SectionRecents recents= {recents} url="offer" />
        </GridItem>

        <GridItem xs={12} sm={8} md={8} id="search" >
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
