
import React from "react";
import ReactMarkdown from "react-markdown";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
// core components
import Badge from "components/Badge/Badge.jsx";
// import Gallery from "./Gallery.jsx";
import ModalAsk from "./ModalAsk.jsx";
import GMap from "KComponents/GMap.jsx";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";


function SectionText({ ...props }) {
  const { classes } = props;
  const { property } = props;

  return (
    <div className={classes.section}>

      <GridContainer justify="center">

        <GridItem xs={1} sm={1} md={1} className={classes.section}>

        </GridItem>

        <GridItem xs={10} sm={10} md={10} className={classes.section}>
          <GridContainer justify="center">


          <GridItem xs={12} sm={6} md={6} className={classes.section}>
            <Quote
              textClassName={classes.quoteText}
              text={"Name: " + property.attributes.name}
              author={"Ref: " + property.attributes.ref }
            />
            <ReactMarkdown source={property.attributes.short_description} />
            <ModalAsk reference={property.attributes.ref} />
          </GridItem>

          <GridItem xs={12} sm={6} md={6} className={classes.section}>

          </GridItem>


          <GridItem xs={12} sm={6} md={6}>
            <h6>Ubicación</h6>
            <h5><strong>País: </strong>{property.attributes.pais.name}</h5>
            <h5><strong>Provincia: </strong>{property.attributes.provincia.name}</h5>
            <h5><strong>Ciudad: </strong>{property.attributes.localidad.name }</h5>
            <h5><GMap /></h5>
          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <h6>Datos</h6>
            <h5><strong>Criterios de inversión: </strong>{property.attributes.precio_publico_texto}</h5>


          </GridItem>
        </GridContainer>
        </GridItem>

        <GridItem xs={1} sm={1} md={1} className={classes.section}>

        </GridItem>




      </GridContainer>



      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <div className={classes.blogTags}>
            Etiquetas:
            { property.attributes.tags.map((tag) =>
              <Badge color="primary">{tag.name}</Badge>
            )}
          </div>
        </GridItem>
      </GridContainer>

      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            {property.attributes.name}
          </h3>
          <p>
            <ReactMarkdown source={property.attributes.description} />
          </p>
        </GridItem>
      </GridContainer>

    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
