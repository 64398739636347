import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import Button from "components/CustomButtons/Button.jsx";
// @material-ui/core components

import withStyles from "@material-ui/core/styles/withStyles";
import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div>
      <GridContainer className={classNames(classes.aboutDescription)}>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto, classes.textCenter)}
        >
          <h2 className={classes.description}>
            Tanto si está planeando vender, alquilar o no esté decidido, tenemos un experto que puede ayudarlo.
          </h2>
          <h3>¿Cuál es el valor de su propiedad?</h3>
          <h5>
            Obtenga asesoramiento especializado para propiedad comercial, residencial o rural, basado en nuestra experiencia y conocimiento de los mercados locales, regionales e internacionales.

          </h5>
          <h5>Con especialistas y socios que trabajan en todos los sectores inmobiliarios, podemos asesorar todo tipo de activos.</h5>
        </GridItem>

        <GridItem md={8}
        sm={8} className={classNames(classes.mrAuto, classes.mlAuto)}>
          <h3 className={classNames(classes.aboutDescription, classes.textCenter)}>Definiendo su criterio de inversión</h3>
          <h5 className={classNames(classes.aboutDescription)}>

Definir sus criterios de inversión es un paso inicial vital para construir una cartera inmobiliaria exitosa o para comprar empresas.
             Si puede determinar qué es lo que está buscando y comunicárselo claramente a nuestro equipo, ahorrará tiempo y esfuerzo a todos y tendrá mejores resultados de inversión.

          El objetivo es que puedas resumir lo que estás buscando en unas pocas frases concisas. De esta manera podemos generar más oportunidades y propuesta de inversión para usted.
        </h5>
        <h5>
          Encuentre nuevas oportunidades de negocio y oferta
          Los inversores, las empresas, los fondos y las firmas de capital privado deben poder encontrar buenos negocios a excelentes precios, explotar y administrarlos mientras sean propietarios y luego saber cuándo es el momento de vender. El abastecimiento es el primer paso crítico y Kinos está enfocado en cómo los GP's se abastecen ”.</h5>
        <h5>

Cuando conocemos su criterio de inversión, Kinos pone a su servicio al profesional especializado en desarrollo empresarial. A lo largo de los años, las firmas de capital privado se han dado cuenta de que es mucho más eficiente tener una persona o un equipo dedicado a cultivar relaciones y encontrar el flujo de negocios adecuado.
          </h5>
          <h5>

  Ayudamos a los clientes que buscan invertir en España, Portugal o en el extranjero a obtener la mejor rentabilidad, abordando todos los desafíos que enfrentan con una perspectiva totalmente integrada.
          </h5>

        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
