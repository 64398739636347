import React from "react";
import ReactMarkdown from "react-markdown";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import ReactStars from 'react-stars'

// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// core components
import ModalAsk from "./ModalAsk.jsx";
import GMap from "KComponents/GMap.jsx";
import Badge from "components/Badge/Badge.jsx";
import Gallery from "./Gallery.jsx";
import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";

function SectionText({ ...props }) {
  const { classes } = props;
  const { property } = props;


  return (
    <div className={classes.section}>

      <GridContainer justify="center">



        <GridItem xs={12} sm={12} md={12} className={classes.section}>
          <GridContainer justify="center">

          <GridItem xs={12} sm={8} md={8} className={classes.section}>
            <Gallery images={property.attributes.images} />
          </GridItem>

          <GridItem xs={12} sm={4} md={4} className={classes.section}>
            <br></br><br></br><br></br><br></br><br></br><br></br>
            <Quote
              textClassName={classes.quoteText}
              text={"Hotel: " + property.attributes.name}
              author={"Ref: " + property.attributes.ref}
            />
            { property.attributes.venta =! null &&
                <Badge color="info">en venta</Badge>


            }
            { property.attributes.alquiler =! null &&

                <Badge color="info">en alquiler</Badge>

            }
            <div className={classes.blogTags}>
              Tags:
              { property.attributes.tags.map((tag) =>
                <Badge color="primary">{tag.name}</Badge>
              )}
            </div>

            <ReactMarkdown source={property.attributes.short_description} />
            <ModalAsk reference={property.attributes.ref} />

          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="info">Ubicación</CardHeader>

          <CardBody>
          <h5><strong>País: </strong>{property.attributes.pais.name}</h5>
          <h5><strong>Provincia: </strong>{property.attributes.provincia.name}</h5>
          <h5><strong>Ciudad: </strong>{property.attributes.localidad.name }</h5>
          <h5><strong>Zona: </strong>{property.attributes.zona}</h5>
          <h5><strong>CP: </strong>{property.attributes.cp}</h5>
          <h5><GMap lati={property.attributes.glatitud} long={property.attributes.glongitud} /></h5>
          </CardBody>
          </Card>

          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <Card>
              <CardHeader color="info">Data</CardHeader>

            <CardBody>
            <h5><strong>Precio: </strong>{property.attributes.precio}</h5>
            <h5><strong>Plantas: </strong>{property.attributes.plantas}</h5>
            <h5><strong>Habitaciones: </strong>{property.attributes.habitaciones}</h5>
            <h5><strong>Estrellas:
               <ReactStars
                  count={5}
                  value={property.attributes.categoria}
                  size={24}
                  edit={false}
                  color2={'#ffd700'} />
                </strong>
            </h5>
            <h5><strong>Fecha de construcción: </strong>{property.attributes.fecha_construccion }</h5>
            </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">Descripción</CardHeader>
              <CardBody>
                <h3 className={classes.title}>
                  {property.attributes.name}
                </h3>
                <p>
                  <ReactMarkdown source={property.attributes.description} />
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        </GridItem>


      </GridContainer>


    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
