import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
// Link
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>

      <GridContainer justify="center">
        <GridItem
            md={12}
            sm={12}
            className={classNames(
              classes.mlAuto,
              classes.mrAuto,
              classes.textCenter
            )}
        >

          <Link to="/retail" smooth><Button color="rose" size="sm" round>LOCALES COMERCIALES</Button></Link>
          <Link to="/industrial" smooth><Button color="rose" size="sm" round>NAVES INDUSTRIALES</Button></Link>
          <Link to="/offices" smooth><Button color="rose" size="sm" round>OFICINAS Y ESPACIOS DE NEGOCIO</Button></Link>
          <Link to="/buildings" smooth><Button color="rose" size="sm" round>EDIFICIOS</Button></Link>
          <Link to="/hotels" smooth><Button color="rose" size="sm" round>HOTELES</Button></Link>
          <Link to="/malls" smooth><Button color="rose" size="sm" round>CENTROS COMERCIALES</Button></Link>
          <Link to="/residential" smooth><Button color="rose" size="sm" round>RESIDENCIAL</Button></Link>
          <Link to="/new_development_properties" smooth><Button color="rose" size="sm" round>OBRA NUEVA</Button></Link>
          <Link to="/urban_lands" smooth><Button color="rose" size="sm" round>SUELOS</Button></Link>
          <Link to="/rural_lands" smooth><Button color="rose" size="sm" round>FINCAS</Button></Link>
          <Link to="/investments" smooth><Button color="rose" size="sm" round>INVERSIONES/PROYECTOS</Button></Link>
          <Link to="/offers" smooth><Button color="rose" size="sm" round>OPORTUNIDADES DE NEGOCIO</Button></Link>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description}>
            Elegidos por nuestro servicio. Famoso por nuestros resultados
          </h2>
          <h3>
            Combinando nuestro conocimiento del mercado regional con la experiencia de la industria, brindamos un servicio completo en todos los asuntos inmobiliarios para propietarios, ocupantes, instituciones financieras, desarrolladores e inversores.</h3>
          <h5>
            Creemos en poner a nuestros clientes en primer lugar y brindarles asesoramiento eficaz y con visión de futuro de una manera accesible que, en última instancia, lo ayude a lograr sus objetivos comerciales y obtener el máximo valor de su propiedad.
          </h5>
          <h5>
            Encuentre su próxima inversión o oportunidad de arrendamiento. Nuestros listados incluyen propiedades de oficinas, industriales y comerciales, así como espacios especializados para multifamiliares, atención médica, tecnología y más.</h5>
        </GridItem>
      </GridContainer>

    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
