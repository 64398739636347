import React from "react";
import ReactMarkdown from "react-markdown";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
// core components
import Badge from "components/Badge/Badge.jsx";
import Gallery from "./Gallery.jsx";
import ModalAsk from "./ModalAsk.jsx";
import GMap from "KComponents/GMap.jsx";
import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";



function SectionText({ ...props }) {
  const { classes } = props;
  const { property } = props;

  return (
    <div className={classes.section}>

      <GridContainer justify="center">


        <GridItem xs={12} sm={12} md={12} className={classes.section}>
          <GridContainer justify="center">

          <GridItem xs={12} sm={8} md={8} className={classes.section}>
            <Gallery images={property.attributes.images} />
          </GridItem>

          <GridItem xs={12} sm={4} md={4} className={classes.section}>
            <br></br><br></br><br></br><br></br><br></br><br></br>
            <Quote
              textClassName={classes.quoteText}
              text={"Property: " + property.attributes.name + ", ref: " + property.attributes.ref}
              author={"Price: " + property.attributes.precio }
            />
            { property.attributes.venta =! null &&
                <Badge color="info">for sale</Badge>


            }
            { property.attributes.alquiler =! null &&

                <Badge color="info">for rent</Badge>

            }

            <ReactMarkdown source={property.attributes.short_description} />
            <ModalAsk reference={property.attributes.ref} />
          </GridItem>

          <GridItem xs={12} sm={4} md={4}>

          <h6>Ubication</h6>
          <h5><strong>Country: </strong>{property.attributes.pais.name}</h5>
          <h5><strong>State: </strong>{property.attributes.provincia.name}</h5>
          <h5><strong>City: </strong>{property.attributes.localidad.name }</h5>
          <h5><strong>Zone: </strong>{property.attributes.zona}</h5>
          <h5><strong>CP: </strong>{property.attributes.cp}</h5>
          <h5><GMap /></h5>

          </GridItem>

          <GridItem xs={12} sm={4} md={4}>
            <h6>Data</h6>
            <h5><strong>Surface: </strong>{property.attributes.superficie_cons}</h5>
            <h5><strong>Bed rooms: </strong>{property.attributes.dormitorios}</h5>
            <h5><strong>Bath rooms: </strong>{property.attributes.bannos}</h5>
            <h5><strong>Exterior: </strong>{property.attributes.exterior}</h5>
            <h5><strong>Terrace: </strong>{property.attributes.terraza}</h5>
            <h5><strong>Garage: </strong>{property.attributes.garaje}</h5>


          </GridItem>

          <GridItem xs={12} sm={4} md={4}>
            <h6>&nbsp;</h6>

            <h5><strong>Date of construction: </strong>{property.attributes.fecha_construccion}</h5>
            <h5><strong>reformed: </strong>{property.attributes.reformado}</h5>
            <h5><strong>Orientation: </strong>{property.attributes.orientacion}</h5>
            <h5><strong>Energetic cert: </strong>{property.attributes.cert_energetica}</h5>
            <h5><strong>Distance to the beach: </strong>{property.attributes.metros_playa}</h5>

          </GridItem>

        </GridContainer>
        </GridItem>

      </GridContainer>



      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <div className={classes.blogTags}>
            Tags:
            { property.attributes.tags.map((tag) =>
              <Badge color="primary">{tag.name}</Badge>
            )}
          </div>
        </GridItem>
      </GridContainer>

      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            {property.attributes.name}
          </h3>
          <p>
            <ReactMarkdown source={property.attributes.description} />
          </p>
        </GridItem>
      </GridContainer>

    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
