import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description, classes.textCenter}>
            USE SU OFICINA DE ASESORAMIENTO  FAMILIAR PARA MANTENERSE ENFOCADO EN EL LEGADO FUTURO
          </h2>

          <h5>Ayudamos a particulares y family offices en la toma de decisiones y en la definición de una estrategia global para sus carteras inmobiliarias.
          </h5>
          <h5>Cada family office tiene como objetivo centralizar la gestión para preservar, hacer crecer y transferir riqueza: nuestro papel es hacer que el proceso sea lo más fluido y eficiente posible.</h5>
          <h3>La importancia de una family office</h3>
          <h5>
            Muchas familias están recurriendo a las oficinas familiares como una forma de satisfacer sus necesidades, brindar un mayor control sobre su patrimonio y manejar sus asuntos. Dado que cada familia tiene su propio conjunto único de necesidades, existen tantos tipos de family offices como familias. Algunos brindan apoyo administrativo básico, mientras que otros supervisan la gestión de activos o se encargan de los asuntos diarios de la familia. Una vez que se establece la family office, es probable que sirva a varias generaciones, entre las cuales puede haber una variedad de necesidades diferentes que requieran varias estrategias de administración de patrimonio. Una family office bien gestionada sintetiza con éxito estas estrategias para garantizar que apoyen los objetivos de gestión patrimonial de la familia en su conjunto.
          </h5>
          <h5>Nuestro equipo ofrece un servicio global y multijurisdiccional desde nuestra base en España, estamos al servicio de familias de patrimonio neto ultra alto, empresarios, familias de alto valor de capital privado y grandes empresas patrimoniales. Agregamos una perspectiva experta e independiente al asesoramiento de inversiones patrimoniales establecido, gracias a nuestro conocimiento de la inversión en propiedades comerciales, de desarrollo, de infraestructura, residenciales y rurales. Nuestra gama de servicios profesionales incluye planificación de sucesión, valoración, testigo experto, mediación, constituciones familiares y asesoramiento sobre la creación de una nueva family office.Contamos con una  gran red network de asesores expertos a nivel local, nacional e internacional y actuamos  junto a todos ellos como un nodo, como un único centro y punto de contacto para family office unifamiliares y multifamiliares por igual, presentando y orquestando equipos de asesoría, especialistas e intermediarios más amplios.
          </h5>
          <h3>Negocio familiar.</h3>
          <h5>
            Estrategias de desarrollo de la empresa familiar
          </h5>
          <h5>Servicios de asesoramiento en la planificación del desarrollo del negocio  familiar,en aspectos de gestión de patrimonios, organizativos y de inversión, teniendo en cuenta los objetivos de la empresa familiar.
</h5>
          <h5>Como parte de los servicios prestados, asesoramos y asesoramos en la creación e implementación de actividades comerciales estratégicas. Además, colaboramos con empresas consultores especializados en cada ámbito que llevan muchos años asesorando en la creación de una estrategia, misión y visión de forma individualizada para cada empresa. Sacamos todo el potencial y valor para el crecimiento de la riqueza de la empresa familiar. Al mismo tiempo, durante la elaboración de los planes de desarrollo, incluimos un tema relevante del futuro de la empresa y la posible sucesión de negocios proponiendo soluciones específicas con anticipación.
          </h5>

        </GridItem>
      </GridContainer>
    </div>
  );
}


export default withStyles(descriptionStyle)(SectionDescription);
