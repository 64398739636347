import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description, classes.textCenter}>
            KINOS CREA UNA VENTAJA TRANSFORMACIONAL PARA ORGANIZACIONES DE SERVICIOS FINANCIEROS COMPLEJAS Y DIVERSAS
          </h2>
          <h3>¿CÓMO PODEMOS AYUDAR?</h3>
          <h5>Las nuevas realidades económicas, la presión regulatoria continua y la amenaza de disrupción de la tecnología son fuerzas implacables que impulsan cambios en la industria de servicios financieros. Estas dinámicas han obligado a las firmas de servicios financieros a concentrarse en controlar los gastos de capital mientras equilibran la necesidad de los mejores talentos, lo que impacta profundamente su presencia en el mercado y sus decisiones inmobiliarias. Los socios especialistas en servicios financieros de Kinos trabajan a diario con bancos, firmas de valores y organizaciones de seguros globales, regionales y locales para aprovechar las ventajas y prepararse para el futuro. Nuestro trabajo aborda desafíos críticos dentro de la optimización de la cartera, la consolidación del espacio, la contención de costos, la gestión del espacio, los servicios de hospitalidad, la integración de fusiones, la gestión de datos y la gestión de riesgos. Nuestra red de contactos financieros tiene alta experiencia y abarca todas las dimensiones del entorno construido para reducir los gastos operativos y posicionar los bienes raíces como un impulsor comercial estratégico.
          </h5>
          <h5>
            Las relaciones duraderas de Kinos, Torus Networks, las capacidades estratégicas y de servicio y el pulso en el mercado permiten una comprensión profunda de las necesidades comerciales únicas de nuestros clientes. Estamos orgullosos de nuestra incomparable práctica y experiencia en servicios financieros.

          </h5>

          <h5>Nuestra práctica de Financiamiento Inmobiliario está enfocada al financiamiento y refinanciamiento de la compraventa y desarrollo de activos inmobiliarios de todo tipo. Contamos con un historial particularmente sólido en el asesoramiento en operaciones de activos operativos, incluidos hoteles, residencias de ancianos, alojamiento para estudiantes, carteras de alquiler de terrenos y el sector de alquiler privado.</h5>
          <h3>¿POR QUÉ ELEGIR NUESTROS SERVICIOS  TRAVÉS DE  RED  DE CONTACTOS FINANCIEROS ESPECIALIZADOS EN  INMOBILIARIA?</h3>
          <h5>Los socios y colaboradores  para cada operación  son diversos. Vamos generando un círculo de contactos expertos en  Finanzas Inmobiliarias para cada operación, con una excelente solidez de relaciones con entidades financieras y  banco s cuidadosamente construida a lo largo de los años y con una cultura de excelencia técnica  logramos captar el capital necesario para las operaciones de inversión requeridas.
</h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
