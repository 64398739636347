import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import classNames from "classnames";
// import Button from "components/CustomButtons/Button.jsx"
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import FooterLinks from "components/Footer/FooterLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

// sections for this page

import SubscribeLine from "./Sections/SubscribeLine.jsx";
import SectionDescription from "./Sections/SectionDescription.jsx";
import ModalOffer from "./Sections/ModalOffer.jsx";
import ModalDemand from "./Sections/ModalDemand.jsx";
import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.jsx";

class BlogPostsPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }


  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          brand="Kinos Corp"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "info"
          }}
        />
      <Parallax image={require("assets/img/Kinos/market.jpg")} filter="dark" small>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
                <h2 className={classes.title}>
                  Comercialice su propiedad con Kinos
                </h2>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classes.main}>
          <GridContainer justify="center">

            <GridItem
              md={2}
              sm={2}
              className={classNames(classes.mrAuto, classes.mlAuto, classes.textCenter)}>
                <ModalOffer />
            </GridItem>

            <GridItem
              md={2}
              sm={2}
              className={classNames(classes.mrAuto, classes.mlAuto, classes.textCenter)}>
              <ModalDemand />
            </GridItem>

          </GridContainer>

          <div className={classes.container}>
            <SectionDescription />
          </div>

          <SubscribeLine />
        </div>
        <Footer>
          <FooterLinks/>
        </Footer>
      </div>
    );
  }
}


export default withStyles(blogPostsPageStyle)(BlogPostsPage);
