import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
// core components

import SectionDestacadas from "KComponents/SectionDestacadas.jsx";
import SectionRecents from "KComponents/SectionRecents.jsx";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";

function SectionText({ ...props }) {
  const { classes } = props;
  const { featured } = props;
  const { recents } = props;

  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            Ya sea que se trate de un solo activo o de una gran cartera, nuestros expertos pueden asesorar a los clientes en todo tipo de propiedad hotelera, ya sea en Madrid, Barcelona, ​​Málaga, regiones de España o globalmente.
          </h3>
          <p>

Cubriendo adquisiciones, valoraciones y transacciones, financiaciones y fusiones y adquisiciones, nuestros especialistas tienen una sólida trayectoria en cada parte del sector, siempre adaptando su asesoramiento a cada cliente, ya sea un individuo, inversor u operador propietario.
            Esto incluye no solo empresas hoteleras, propiedades inmobiliarias hoteleras, sino también apartamentos, hostales, complejos turísticos e instalaciones institucionales. Nuestra red global integrada (Torus Network) de más de 700 socios está bien ubicada en Asia-Pacífico, el Reino Unido, Europa, Estados Unidos y China, lo que nos brinda un posicionamiento único para acceder a fuentes competitivas de capital.
          </p>
          <Quote
            textClassName={classes.quoteText}
            text="“¡La excelencia es nuestro objetivo, la satisfacción de los clientes es nuestro orgullo!”"
            author="Joaquín Morales, CEO."
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={10} className={classes.section}>
          <SectionDestacadas featured= {featured} url="hotel" />
          <SectionRecents recents= {recents} url="hotel" />
        </GridItem>
        <GridItem xs={12} sm={8} md={8} id="search" >
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
