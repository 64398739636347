import React from "react";
import ReactMarkdown from "react-markdown";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// import ReactStars from 'react-stars'

// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// core components
import ModalAsk from "./ModalAsk.jsx";
import GMap from "KComponents/GMap.jsx";
import Badge from "components/Badge/Badge.jsx";
import Gallery from "./Gallery.jsx";
import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";



function SectionText({ ...props }) {
  const { classes } = props;
  const { property } = props;

  return (
    <div className={classes.section}>

      <GridContainer justify="center">


        <GridItem xs={12} sm={12} md={12} className={classes.section}>
          <GridContainer justify="center">

          <GridItem xs={12} sm={8} md={8} className={classes.section}>
            <Gallery images={property.attributes.images} />
          </GridItem>

          <GridItem xs={12} sm={4} md={4} className={classes.section}>
            <br></br><br></br><br></br><br></br><br></br><br></br>
            <Quote
              textClassName={classes.quoteText}
              text={"Property: " + property.attributes.name}
              author={"Ref: " + property.attributes.ref}
            />
            { property.attributes.venta =! null &&
                <Badge color="info">for sale</Badge>


            }
            { property.attributes.alquiler =! null &&

                <Badge color="info">for rent</Badge>

            }
            <div className={classes.blogTags}>
              Tags:
              { property.attributes.tags.map((tag) =>
                <Badge color="primary">{tag.name}</Badge>
              )}
            </div>

            <ReactMarkdown source={property.attributes.short_description} />
            <ModalAsk reference={property.attributes.ref} />

          </GridItem>


          <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="info">Ubication</CardHeader>

          <CardBody>
          <h5><strong>Country: </strong>{property.attributes.pais.name}</h5>
          <h5><strong>State: </strong>{property.attributes.provincia.name}</h5>
          <h5><strong>City: </strong>{property.attributes.localidad.name }</h5>
          <h5><strong>Zone: </strong>{property.attributes.zona}<strong> CP: </strong>{property.attributes.cp}</h5>
          <h5><GMap /></h5>
          </CardBody>
          </Card>

          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <Card>
              <CardHeader color="info">Data</CardHeader>

            <CardBody>
              <h5><strong>Surface area: </strong>{property.attributes.superficie}</h5>
              <h5><strong>Energetic cert: </strong>{property.attributes.cert_energetica}</h5>
              <h5><strong>Opening licence: </strong>{property.attributes.licencia_apertura }</h5>
              <h5><strong>Date of construction: </strong>{property.attributes.fecha_construccion }</h5>
            </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">Description</CardHeader>
              <CardBody>
                <h3 className={classes.title}>
                  {property.attributes.name}
                </h3>
                <p>
                  <ReactMarkdown source={property.attributes.description} />
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        </GridItem>

      </GridContainer>



    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
