import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
// core components

import SectionDestacadas from "KComponents/SectionDestacadas.jsx";
import SectionRecents from "KComponents/SectionRecents.jsx";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";

function SectionText({ ...props }) {
  const { classes } = props;
  const { featured } = props;
  const { recents } = props;
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            Ofrecemos una variedad de servicios que abarcan múltiples áreas de práctica, incluida la adquisición y disposición de espacios, así como servicios de consultoría estratégica en todos los sectores del mercado.
          </h3>
          <p>
            Con profesionales enfocados en el sector de oficinas que cualquier otra firma a nivel mundial, Kinos Office Services ofrece soluciones inmobiliarias corporativas innovadoras para satisfacer las necesidades específicas tanto de ocupantes / inquilinos como de propietarios / inversionistas.
Fortalecidos con pronósticos econométricos líderes en la industria, bases de datos patentadas y capacidades de investigación y marketing de servicio completo, nuestros profesionales colaboran a través de fronteras geográficas y líneas de servicio para ofrecer un valor significativo a nuestros clientes. Además, impulsado por la plataforma de tecnología global de Kinos (Torus Network) que conecta a nuestros asesores de clase mundial en todos los rincones del mundo, brindamos información y experiencia del mercado local en tiempo real que ofrece resultados sólidos para los ocupantes que operan a nivel local, nacional o nivel global.
          </p>
          <Quote
            textClassName={classes.quoteText}
            text="“¡La excelencia es nuestro objetivo, la satisfacción de los clientes es nuestro orgullo!”"
            author="Joaquín Morales, CEO."
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={10} className={classes.section}>
          <SectionDestacadas featured={featured} url="office" />
          <SectionRecents recents={recents} url="office" />
        </GridItem>

        <GridItem xs={12} sm={8} md={8} id="search" >
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
