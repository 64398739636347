import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Money from "@material-ui/icons/Money";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// import sectionCommentsStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionCommentsStyle.jsx";
import sectionCommentsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function ModalFilter({ ...props }) {
  const { classes } = props;
  const [signupModal, setSignupModal] = React.useState(false);
  return (
    <div>
        <div className={classes.textCenter}>
        <Button color="primary" round onClick={() => setSignupModal(true)} >
          Filtrar Resultados
        </Button>
        </div>


    <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSignup
        }}
        open={signupModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSignupModal(false)}
        aria-labelledby="signup-modal-slide-title"
        aria-describedby="signup-modal-slide-description"
      >
        <Card plain className={classes.modalSignupCard}>
          <DialogTitle
            id="signup-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              color="primary"
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => setSignupModal(false)}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>

          </DialogTitle>
          <DialogContent
            id="signup-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
              <div className={classes.textCenter}>
                <h2 className={classes.socialTitle}>Filter criteria</h2>
              </div>
              </GridItem>

              <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>

                <form className={classes.form}>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Money className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      placeholder: "Max Price..."
                    }}
                  />

                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Icon className={classes.inputAdornmentIcon}>
                            edit
                          </Icon>
                        </InputAdornment>
                      ),
                      placeholder: "Name..."
                    }}
                  />


                </form>
              </GridItem>

              <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>

                <form className={classes.form}>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Money className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      placeholder: "Min Price..."
                    }}
                  />

                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Icon className={classes.inputAdornmentIcon}>
                            edit
                          </Icon>
                        </InputAdornment>
                      ),
                      placeholder: "Zone..."
                    }}
                  />


                </form>
              </GridItem>

              <div className={classes.textCenter}>
                <Button round color="primary" onClick={() => setSignupModal(false)}>
                  Filtrar
                </Button>
              </div>

            </GridContainer>

          </DialogContent>
        </Card>
      </Dialog>
      </div>
  );
}

export default withStyles(sectionCommentsStyle)(ModalFilter);
