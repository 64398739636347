import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import BulletOne from "@material-ui/icons/LooksOne";
import BulletTwo from "@material-ui/icons/LooksTwo";
import BulletThree from "@material-ui/icons/Looks3";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.jsx";

function SectionServices(props) {
  const { classes } = props;
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>La gestión se basa en tres pilares</h2>
          <h5 className={classes.description}>

          </h5>

          <h5 className={classes.description}>
El interés de nuestros clientes es el núcleo de nuestra filosofía de gestión de activos.
También adaptamos sus estrategias de inversión a las necesidades específicas de los clientes.
</h5>
        </GridItem>
      </GridContainer>
      <GridContainer>

        <GridItem md={4} sm={4}>
          <InfoArea
            title="PLANEAMIENTO"
            description={
              <span>
                <p>
                  Apoyo en el diseño y materialización de estructuras de inversión y el diseño de planes de negocio.
                  La gestión del ciclo de vida de los activos (ALM) es el proceso de optimización de las ganancias generadas por sus activos a lo largo de su ciclo de vida.
                  La gestión integral de la cartera de activos, la ejecución rigurosa de proyectos y las prácticas de gestión de activos eficaces y eficientes ayudan a obtener los resultados deseados.
                  Nuestro conjunto de ofertas de servicios aborda todas las etapas del ciclo de vida de un activo, independientemente de su alcance, escala o industria.
                  Basándonos en años de experiencia y una multitud de conjuntos de habilidades a nivel local y de toda España, nos aseguramos de que sus necesidades comerciales estén respaldadas ayudándolo a lograr un rendimiento óptimo de su base de activos.
                </p>

              </span>
            }
            icon={BulletOne}
            iconColor="rose"
          />
        </GridItem>

        <GridItem md={4} sm={4}>
          <InfoArea
            title="GESTIÓN"
            description={
              <span>
                <p>
                  Ejecución de planes de negocio, explotación comercial y gestión societaria (contable y fiscal).
                  El área de Asset Management centra su atención tanto en la optimización de las operaciones diarias como en la maximización de los ingresos con el fin de optimizar el valor de los inmuebles.
                  El grado de diálogo alcanza tres niveles: Activo y Corporación, Instituciones Financieras e Inversores.
                </p>

              </span>
            }
            icon={BulletTwo}
            iconColor="rose"
          />
        </GridItem>

        <GridItem md={4} sm={4}>
          <InfoArea
            title="ESTRATEGIA DE SALIDA"
            description={
              <span>
                <p>
                  Preparación de data room, soporte en la negociación de venta y ejecución de condición post transacción.
                  Debida diligencia vendedor / comprador para identificar riesgos, con la participación de todas las áreas especializadas.
                    <ol>
                      <li> Diseño de una estructura legal y fiscal eficiente, identificando el vehículo de inversión más adecuado. </li>
                      <li> Identificación de las fuentes de financiamiento más competitivas en los mercados tradicionales y alternativos. </li>
                      <li> Negociación, diseño y formalización del contrato de inversión. </li>
                      <li> Saliendo </li>
                      <li> Disponer de la propiedad. </li>
                      <li> Análisis para determinar el momento final y el diseño de la estrategia de salida. </li>
                    </ol>
                  Con la máxima confidencialidad, contactamos con potenciales inversores nacionales e internacionales con cuyos perfiles encajamos.

                </p>

              </span>
            }
            icon={BulletThree}
            iconColor="rose"
          />
        </GridItem>



      </GridContainer>
    </div>
  );
}

export default withStyles(servicesStyle)(SectionServices);
