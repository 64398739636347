import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionEnd(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description}>
            Presentamos la fuerza de ventas de KINOS & TORUS
          </h2>

          <h5>Disfrutamos enormemente de nuestra relación de trabajo con TORUS & PARTNERS NETWORK. Siempre están preparados y son proactivos, trayendo nuevas ideas a la mesa y ejecutándolas a un alto nivel. Las estrategias están bien concebidas y siempre con nuestros intereses y objetivos en mente.
          </h5>
          <h5>Nuestros sistemas de ventas únicos y avanzados impulsarán su proyecto hacia los resultados que busca. Desde la estrategia de ventas hasta la gestión de ventas, nuestro equipo altamente capacitado está aquí para informar la progresión. Nos complace informarle con más detalles sobre cada elemento del proceso de nuestra fuerza de ventas y cómo se conecta con el ciclo de vida del desarrollo.</h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionEnd);
