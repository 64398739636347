import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Separator from "@material-ui/icons/LinearScale";
import Bullet from "@material-ui/icons/DragIndicator";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.jsx";

function SectionServices(props) {
  const { classes } = props;
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >

          <Separator /><Separator /><Separator />

          <h2 className={classes.title}>Lo que ofrecemos</h2>
          <h5 className={classes.description}>
            La cultura de asociación de Kinos, nuestro compromiso con la excelencia y la integridad intransigente nos convierte en una excelente opción para inversores con una visión a largo plazo.
          </h5>

          <h5 className={classes.description}>El interés de nuestro cliente primero es el núcleo de nuestra filosofía de gestión de activos. También adaptamos sus estrategias de inversión a las necesidades específicas de los cliente.
</h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="PRIMERA CLASE."
            description={
              <span>
                <p>
                  Tenemos una reputación de servicio al cliente de primera clase, que se puede brindar a través de una serie de modelos diferentes, desde un servicio dirigido por socios por líderes reconocidos en la industria, hasta un enfoque más mercantilizado utilizando la subcontratación y las últimas soluciones de TI.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="GESTIÓN DE TODAS LAS AREAS."
            description={
              <span>
                <p>
                  Además del típico trabajo transaccional de financiación inmobiliaria garantizada, cada vez estamos más involucrados asesorando en préstamos en  refinaciaciones, estructuración de deudas, reestructuraciones y en situaciones especiales, donde involucramos a otros expertos de la Red Network Kinos.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="LIDERAZGO CODUCIENDO NUEVAS IDEAS."
            description={
              <span>
                <p>
                  Estamos comprometidos y nos apasiona la financiación inmobiliaria y, además de prestar servicio a nuestros clientes, estamos involucrados con varios organismos de la industria, impulsando de forma proactiva el liderazgo intelectual y el desarrollo de los mercados financieros inmobiliarios.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>

      </GridContainer>
    </div>
  );
}


export default withStyles(servicesStyle)(SectionServices);
