import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
// core components

import SectionDestacadas from "KComponents/SectionDestacadas.jsx";
import SectionRecents from "KComponents/SectionRecents.jsx";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";

function SectionText({ ...props }) {
  const { classes } = props;
  const { featured } = props;
  const { recents } = props;
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            En cada escenario, nos enfocamos en la comunicación con el cliente y la entrega oportuna.
          </h3>
          <p>
            En el núcleo de nuestro servicio se encuentra un enfoque nacional y conjunto de agencias industriales y logísticas, compuesto por expertos con base en Madrid y mercados regionales clave, que nos brindan una amplia cobertura para ayudar a los clientes en todas las áreas.
Nuestro éxito y fortaleza se derivan de nuestra capacidad para brindar un servicio integrado que combina especialistas que ofrecen experiencia en una variedad de líneas de servicio asociadas. Estos cubren todos los elementos del sector, incluyendo bienes raíces corporativas, financiación, consultoría de edificación, gestión de proyectos, consultoría de arrendamiento, inversión, valoración, planificación y desarrollo. Tener esta red de expertos de España conectada (Torus Network) permite el seguimiento y seguimiento constante de la actividad en los mercados locales, regionales y nacionales.
Nuestros socios asisten y asesoran a algunos de los actores más importantes y activos del sector industrial y logístico, incluidos fondos / inversores, desarrolladores y ocupantes.
          </p>
          <Quote
            textClassName={classes.quoteText}
            text="“¡La excelencia es nuestro objetivo, la satisfacción de los clientes es nuestro orgullo!”"
            author="Joaquín Morales, CEO."
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={10} className={classes.section}>
          <SectionDestacadas featured={recents} url="industrial" />
          <SectionRecents recents={recents} url="industrial" />
        </GridItem>
        <GridItem xs={12} sm={8} md={8} id="search" >
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
