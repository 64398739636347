import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import adquisition from "assets/img/Kinos/Developers/adquisition.jpeg"
import development from "assets/img/Kinos/Developers/development.jpeg";
import marketing from "assets/img/Kinos/Developers/marketing.jpeg";
import completion from "assets/img/Kinos/Developers/completion.jpg";
import construction from "assets/img/Kinos/Developers/construction.jpeg";
import sales from "assets/img/Kinos/Developers/sales.jpg";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.jsx";

function SectionInterested({ ...props }) {
  const { classes } = props;
  return (
    <div className={classes.section}>
      <h3 className={classes.title + " " + classes.textCenter}>
        CICLO DE DESARROLLO
      </h3>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>

                <img src={adquisition} alt="..." />

              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + adquisition + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>

              <h4 className={classes.cardTitle}>

                  ADQUISICION

              </h4>
              <p className={classes.description}>

Nuestro profundo conocimiento del mercado y un sofisticado análisis de inversiones informarán su adquisición y lo conducirán hacia un resultado exitoso.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>

                <img src={development} alt="..." />

              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + development + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>

              <h4 className={classes.cardTitle}>

                  DESARROLLO

              </h4>
              <p className={classes.description}>
                Desde respaldar el proceso de aplicación de desarrollo hasta influir en un diseño atractivo, hacemos avanzar su desarrollo hasta convertirlo en un producto altamente comercializable.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>

                <img src={marketing} alt="..." />

              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + marketing + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>

              <h4 className={classes.cardTitle}>

                  COMERCIALIZACIÓN

              </h4>
              <p className={classes.description}>
                Con una comprensión integral de los compradores y el mercado, diseñamos, organizamos y administramos un programa de marketing adaptado a su desarrollo único.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>

                <img src={sales} alt="..." />

              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + sales + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <h4 className={classes.cardTitle}>
                  VENTAS Y ARRENDAMIENTO
              </h4>
              <p className={classes.description}>
                A través de amplios programas de capacitación y administración, nuestro enfoque de ventas de varios niveles y de clase mundial se ha perfeccionado para generar ventas más allá de las expectativas.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
                <img src={construction} alt="..." />
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + construction + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <h4 className={classes.cardTitle}>
                  CONSTRUCCIÓN
              </h4>
              <p className={classes.description}>
                A medida que su visión se convierte en realidad, administramos las ventas de manera proactiva y continuamos trabajando incansablemente a través de una colaboración impecable con los proveedores y la comunicación con los compradores de viviendas.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
                <img src={completion} alt="..." />
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + completion + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <h4 className={classes.cardTitle}>
                  TERMINACIÓN
              </h4>
              <p className={classes.description}>
                Una transacción fluida fortalece la relación entre el desarrollador y el comprador durante todo el proceso de finalización, generando una experiencia positiva y un valor de marca futuro.
              </p>
            </CardBody>
          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(sectionInterestedStyle)(SectionInterested);
