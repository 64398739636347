import React from "react";
import axios from 'axios';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import ArrowBack from "@material-ui/icons/ArrowBack";

// core components
import { Link } from "react-router-dom";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Footer from "components/Footer/Footer.jsx";
import FooterLinks from "components/Footer/FooterLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
// sections for this page
import SectionText from "./Sections/SectionText.jsx";
import SectionDestacadas from "./Sections/SectionDestacadas.jsx";

import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.jsx";

class NewDevelopment extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      property: { id: '',
                     attributes: {
                       pais: {},
                       provincia: {},
                       localidad: {},
                       tags: []
                     }
                   },
      featured: [],
      image: ''
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { match } = this.props;
    const propertyId = Number(match.params.id);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    let urlResult = 'https://api.kinosgroup.com/api/v1/es/promociones/' + propertyId ;
    let urlFeatured = 'https://api.kinosgroup.com/api/v1/es/promociones?per_page=3&sort=categoria&direction=desc' ;

    const requestResult = axios.get(urlResult);
    const requestFeatured = axios.get(urlFeatured);

    axios
      .all([requestResult, requestFeatured])
      .then(
          axios.spread((...responses) => {
            const responseResult = responses[0];
            const responseFeatured = responses[1];

            if (this._isMounted) {
              this.setState({ property: responseResult.data.data });
              this.setState({ image: responseResult.data.data.attributes.images[0].attributes.adjunto_large });
              this.setState({ featured: responseFeatured.data.data });
              console.log(responseResult.data.data, responseFeatured.data.data);
            }

          })
        )
        .catch(errors => {
          // react on errors.
          console.error(errors);
        });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {
    const { classes } = this.props;
    // const { hotel } = this.props.location.state;
    const {property, image} = this.state;
    const {featured} = this.state;

    return (
      <div>
        <Header
          brand="Kinos Corp"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "info"
          }}
        />

      <Parallax image={`https://wsrv.nl/?url=${image}`} filter="dark">
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem md={8} className={classes.textCenter}>
                <h1 className={classes.title}>
                  {property.attributes.name}
                </h1>
                <h4 className={classes.subtitle}>
                  {property.attributes.short_description}
                </h4>
                <br />
                <Link to='/new_development_properties'>
                  <Button color="rose" size="lg" round>
                    <ArrowBack /> Go Back
                  </Button>
                </Link>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classes.main}>
          <div className={classes.container}>
            <SectionText property={property} />
          </div>
        </div>
        <SectionDestacadas featured={featured} />
          <Footer>
            <FooterLinks/>
          </Footer>
      </div>
    );
  }
}

export default withStyles(blogPostPageStyle)(NewDevelopment);
