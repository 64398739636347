import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description, classes.textCenter}>
            SERVICIOS DE VALORACIÓN Y ASESORAMIENTO
          </h2>
          <h3>SERVICIOS PARA INVERSORES</h3>
          <h5>Kinos proporciona servicios integrales de valoración, asesoría y consultoría para propietarios de activos inmobiliarios  inversores y prestamistas, combinando nuestra experiencia especializada y tecnologías de primer nivel para ofrecer informes y análisis de valoración líderes en la industria inmobiliaria.
          </h5>
          <h5>Calcula el valor real de tu activo o cartera  de inmuebles en cualquier sector, en cualquier lugar de España. Obtenga una evaluación rápida, confiable y precisa de profesionales con licencia incluso para los proyectos más complejos.
          </h5>
          <h5>Kinos trabaja con ampliar red de colaboradores expertos en cada área para evaluar cada activo en cada región.</h5>
          <h5>
            Experiencia inigualable y líder en la industria En Kinos, entendemos que las valoraciones precisas y confiables son fundamentales para el éxito de cada inversión inmobiliaria, por lo que ofrecemos a nuestros clientes perspectivas únicas y acceso a la base de datos de valoración más grande y a los datos de propiedad más valiosos en La industria. Utilizamos nuestra amplia escala global, tecnología de vanguardia y procesos de innovación comprobados para brindar servicios de valoración, asesoría y consultoría excepcionales en todos los tipos de propiedades y geografías Los expertos de los socios de valoración global de Kinoss, ubicados en todas las geografías, permiten soluciones de cliente personalizadas para activos individuales o carteras de propiedades múltiples. Aprovechamos nuestras perspectivas, innovación y escala incomparables para brindar servicios líderes de valoración, asesoría y consultoría, lo que permite a nuestros clientes tomar decisiones comerciales informadas.
          </h5>
          <h5>
            Comprender y optimizar el valor Llegar a una opinión sobre el valor de una propiedad es un proceso complejo y disciplinado que puede tener un impacto significativo en su negocio. Una tasación bien respaldada es a menudo un paso necesario para asegurar un préstamo, elegir el mejor activo para su cartera y cerrar una venta.
          </h5>
          <h5>
            Nuestra extensa plataforma global líder en la industria nos permite brindar garantía de calidad de manera constante en una amplia gama de servicios en muchas propiedades diferentes.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
