import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description, classes.textCenter}>
            Broker, conectando inversores y demandantes
          </h2>
          <h3>
Compra, negocia, vende, invierte, crece, gana</h3>
          <h5>
            Kinos siempre está buscando oportunidades para inversores.
Tenemos una gran cartera que incluye reposicionamiento de activos individuales, desinversiones corporativas, programas de empresas conjuntas, inversiones en entidades, oportunidades de desarrollo y acuerdos de deuda. Los activos pueden adquirirse individualmente o en operaciones de cartera.
          </h5>
          <h5>Por favor, si desea realizar alguna inversión como comprador o como vendedor desea vender, envíenos sus criterios de inversión o información de sus activos.</h5>
          <h5>Nos especializamos en la comercialización de activos inmobiliarios. Trabajamos con la última tecnología en gestión de activos, bases de datos y marketign online. Si desea vender un activo desde una vivienda a todo tipo de activos inmobiliarios corporativos.
Gestión de calidad aplicada al proceso de venta, servicio profesional con reporte detallado y seguimiento de cada operación. </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
