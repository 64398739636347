import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description, classes.textCenter}>
            ANALIZAR LAS TENDENCIAS PROPORCIONA BENEFICIOS QUE PERMITEN LA  ADECUADA TOMA DE DECISIONES
          </h2>
          <h3>MEJORES ANÁLISIS DE PERSPECTIVAS. MEJORES INVERSIONES</h3>
          <h5>
            Kinos proporciona soluciones de inteligencia empresarial a un espectro completo de clientes.
            Ya sean Bancos, Compañías de Financiamiento de Vivienda, Desarrolladores, Fondos, Compañías de Seguros Hipotecarios o Proveedores,
            la prospección inmobiliaria les ayuda a identificar las oportunidades, así como a medir los riesgos asociados, en el mercado.
          </h5>

          <h5>
            La inteligencia de mercado es fundamental para tomar las mejores decisiones para su negocio Tecnología de vanguardia y personal dedicado,
            es la combinación que le brinda los datos más completos, detallados y precisos que existen.
          </h5>

        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
