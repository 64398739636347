import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.jsx";

class SectionProduct extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>Líder en servicios inmobiliarios y gestión de inversiones.</h2>
            <h5 className={classes.description}>
Kinos proporciona resultados excepcionales para los clientes al combinar conocimientos del mercado local, amplios servicios, experiencia especializada y herramientas y recursos tecnológicos de primer nivel.
              Brindamos asesoramiento y servicios de expertos para maximizar el valor de la propiedad para los ocupantes, propietarios e inversores de bienes raíces.
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Seguridad"
                description="Las inversiones seguras, la información y el asesoramiento son las mejores herramientas."
                icon={Chat}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Ideas"
                description="Ideas, fuente de valor, conocimiento, sabiduría en inversiones. Poder del pensamiento."
                icon={VerifiedUser}
                iconColor="success"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Innovación"
                description="Seguir avanzando, investigación, creatividad, conocimiento, nuevos proyectos."
                icon={Fingerprint}
                iconColor="danger"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(SectionProduct);
