import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description}>

VISIÓN INTELIGENTE INMOBILIARIA
          </h2>
          <h3>
Servicios para constructores, promotores inmobiliarios e inversores</h3>
          <h5>
            Kinos Land & New Homes nuestro objetivo es simplificar el proceso de compra y venta de nuevas viviendas y propiedades relacionadas, incluidos el intercambio de piezas y los esquemas asistidos.
Nuestra estrategia es crear alianzas con desarrolladores, constructores y todo tipo de socios para apoyar sus objetivos y agregar valor a sus negocios.
          </h5>
          <h5>
Hemos refinado nuestros servicios y agregado nuevas ofertas. está elevando el listón y nuestro compromiso con un servicio de clase mundial para nuestros clientes, compradores de vivienda, clientes y socios, y mucho más.</h5>
          <h3>
Innovación en una industria que nunca deja de moverse.</h3>
          <h5>Nuestros negocios combinados de agencias inmobiliarias actúan actualmente para un gran número de constructores de viviendas en toda España. Nuestros servicios se pueden adaptar para satisfacer las necesidades individuales, desde ventas y marketing de sitios a medida, referencias de agencias hasta la eliminación de intercambio de piezas, esquemas asistidos y stock de nueva construcción, ventas de terrenos y adquisiciones.</h5>
          <h3>
Al servicio de comunidades exitosas.</h3>
          <h5>Estrategias en cada paso con nuestras décadas de experiencia como una empresa de marketing, ventas y arrendamiento de bienes raíces de servicio completo, hemos configurado una fórmula personalizable pero definitiva para lograr el éxito. Cada etapa del ciclo de vida del desarrollo se planifica y ejecuta meticulosamente, respetando las tendencias y los pronósticos del mercado para producir resultados. Los grupos de nuestro equipo están altamente especializados en cada área y trabajan en colaboración para realizar una transición fluida entre etapas, esforzándose por lograr la eficiencia y distinguir las soluciones del mercado.</h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
