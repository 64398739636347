import React from "react";
// @material-ui/core components
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import Button from "components/CustomButtons/Button.jsx";
// import Paginations from "components/Pagination/Pagination.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Info from "components/Typography/Info.jsx";
// Style
import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.jsx";

function SectionNewsList({ ...props }) {
  const { classes, handleViewMoreResults, total, perPage } = props;
  // const { news } = props;
  var news = props.news.map((hotel) => {
    let url = '/blog-post/' + hotel.id;
    return(
      <GridItem xs={12} sm={4} md={4}>
        <Card blog>
          <CardHeader image>
            <Link to={{
                        pathname: url,
                        state: {
                          hotel: hotel.attributes
                        }
                      }}>
              <img src={`https://wsrv.nl/?url=${hotel.attributes.adjunto_medium}`} alt="..." />
            </Link>
            <div
              className={classes.coloredShadow}
              style={{
                backgroundImage: `https://wsrv.nl/?url=${hotel.attributes.adjunto_medium}`,
                opacity: "1"
              }}
            />
          </CardHeader>
          <CardBody>

            <h6 className={classes.cardTitle}>
              <Link to={url}>
                <Info>
                {hotel.attributes.title}
                </Info>
              </Link>
            </h6>

            <p className={classes.description}>
              {hotel.attributes.leadin}...
              <Link to={{
                          pathname: url,
                          state: {
                            hotel: hotel.attributes
                          }
                        }}>
                           Leer más
              </Link>
            </p>
          </CardBody>
        </Card>
      </GridItem>

    )
  })

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={12}>
            <h2 className={classes.title + " " + classes.textCenter}>
              { (perPage < total )
               ? `Mostrando ${perPage} de ${total} noticias`
               : `Mostyrando un total de ${total} noticias`
              }
            </h2>

            <br />

            <GridContainer>
              {news}
            </GridContainer>

            <br />

            <h2 className={classes.title + " " + classes.textCenter}>
                { (perPage < total )
                  ? `Mostrando ${perPage} de ${total} noticias`
                  : `Mostrando un total de ${total} noticias`
                }
              </h2>

          </GridItem>

          <GridItem md={12} className={classes.textCenter}>
            { (perPage < total )
                ? <Button round onClick={handleViewMoreResults} color="primary">
                  Ver más...
                </Button>
                : ''
            }
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

export default withStyles(sectionInterestedStyle)(SectionNewsList);
