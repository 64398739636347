import React from "react";

// nodejs library that concatenates classes

import GoogleMaps from "simple-react-google-maps";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.jsx";

function GMap(lati, long, ...props)  {
  // const property = props;
  // const { lati, long } = props;
  // var latitude, longitude;
  // (lati == null) ? latitude = 36.7351762 : latitude = lati;
  // (long == null) ? longitude = -4.43397 : longitude = long;

  console.log("Latidute: ", lati.lati);
  console.log("Longitude: ", lati.long);

  return (
      <GridContainer>
        <GridItem xs={12} lg={12} mb={10}>
          <h5 my={1} mb={10}>
            Mapa: description
          </h5>

          <GoogleMaps
            apiKey="AIzaSyA42726T2lipqBYT7F5P98GFSH5rQ9MiSo"
            style={{ height: "400px", width: "100%" }}
            zoom={11}
            center={{ lat: Number(lati.lati), lng: Number(lati.long) }}
            markers={{ lat: Number(lati.lati), lng: Number(lati.long) }}
          />
        </GridItem>
      </GridContainer>

  );

}
//

export default GMap;
