import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.jsx";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

// @material-ui/icons
import Mail from "@material-ui/icons/Email";

// office
import office1 from "assets/img/Kinos/AboutUs/office1.jpg";
import office2 from "assets/img/Kinos/AboutUs/office2.jpg";
import office4 from "assets/img/Kinos/AboutUs/office4.jpg";
import description from "assets/img/bg.jpg";

function SectionOffice(props) {
  const { classes } = props;
  return (
    <div className={classes.office}>

      <GridContainer>

        <GridItem xs={12} sm={12} md={12}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + description + ")" }}
          >
            <CardBody background>
              <h6 className={classes.category}>KINOS</h6>
              <a href="#">
                <h3 className={classes.cardTitle}>
                  Más de 30.000 Clientes nos avalan.
                </h3>
              </a>
              <p className={classes.category}>
                Le ayudamos a comprar, construir, ocupar e invertir en una variedad de activos, incluidos bienes raíces industriales, comerciales,
                minoristas, residenciales y hoteleros. Desde fondos de inversión, nuevas empresas tecnológicas hasta empresas globales, nuestros
                clientes abarcan industrias que incluyen banca, energía, atención médica, derecho, ciencias de la vida, manufactura y tecnología.
              </p>
              <Button round href="/contact-us" color="primary">
                <Mail className={classes.icons} /> Contact Us
              </Button>

            </CardBody>
          </Card>
        </GridItem>

        <GridItem md={6} sm={6}>
          <h3 className={classes.title}>¿Qiénes somos?</h3>
          <h4>
            Kinos fue establecida en 2003 por sus socios fundadores Joaquín Morales y Margarita Morales en las últimas décadas, han acumulado un currículum completo de educación y experiencia en administración de empresas y bienes raíces. También ha establecido una sólida red de contactos en la industria y lazos con España, Europa, Estados Unidos y la comunidad global. Gestiona las operaciones diarias de la empresa y supervisa todas las áreas de operaciones.
          </h4>
        </GridItem>



        <GridItem md={6} sm={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office1}
            alt="office1"
          />
        </GridItem>






        <GridItem md={6} sm={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office2}
            alt="office2"
          />
        </GridItem>

        <GridItem md={6} sm={6}>
          <h3 className={classes.title}>¿Porqué Kinos?</h3>
          <h4>
            Kinos es una firma de gestión de inversiones y servicios profesionales que ofrece servicios inmobiliarios especializados a clientes que buscan un mayor valor al poseer, ocupar e invertir en bienes raíces.
  Aplicamos las herramientas de la ciencia de datos moderna a los bienes raíces comerciales y residenciales. Durante décadas, hemos analizado los fundamentos del mercado con coherencia en todas las geografías y tipos de propiedades en España. Nuestros datos, análisis y puntos de vista macroeconómicos brindan información a medida que los clientes evalúan los riesgos y oportunidades que los cambios del mercado pueden representar para sus carteras de inversión.
          </h4>
        </GridItem>


        <GridItem md={6} sm={6}>
          <h3 className={classes.title}>Nuestro objetivo</h3>
          <h4>
            Kinos busca identificar oportunidades de inversión relacionadas con el sector inmobiliario en España y Europa. Buscamos generar rentabilidades atractivas ajustadas al riesgo con potencial alcista a través de la revalorización o reposicionamiento del capital, con un enfoque particular en las principales áreas metropolitanas de España y los mercados superregionales en Europa.
          </h4>
        </GridItem>

        <GridItem md={6} sm={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office4}
            alt="office4"
          />
        </GridItem>



      </GridContainer>
    </div>
  );
}

export default withStyles(officeStyle)(SectionOffice);
