import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description, classes.textCenter}>
            FIRST-CLASS BUSINESS, IN A FIRST-CLASS WAY
          </h2>


          <h5>
            Estamos comprometidos con una cultura distintiva y un conjunto de valores fundamentales. Estos valores se reflejan en nuestros Principios Empresariales,
             que enfatizan anteponer los intereses de nuestros clientes, la integridad, el compromiso con la excelencia y la innovación y el trabajo en equipo.
          </h5>
          <h5>Kinos se centra en las necesidades y preferencias de inversión de nuestros clientes. Constantemente hemos obtenido resultados atractivos, a pesar de los cambios de mercado.
condiciones y en los ciclos del mercado.</h5>
          <h5>Our goal is to be the advisor of choice for our clients and a leading participant in global real estate and financial markets. Kinos provide services customers around worldwide, to a substantial and diversified client base, which includes, family office, corporations, financial institutions, governments, sovereign wealth funds and high net worth individuals.
</h5>
          <h3>SERVICIOS PARA INVERSORES</h3>
          <h5>
Ofrecemos una sólida gama de servicios para inversores diseñados para impulsar el rendimiento de los bienes raíces y el valor de los activos.
Nuestra posición de liderazgo en relaciones y redes nos brinda acceso a oficinas familiares, instituciones financieras y clientes corporativos a nivel mundial.
 La gestión de la riqueza inmobiliaria es uno de los segmentos de más rápido crecimiento de la industria de servicios inmobiliarios y estamos posicionando nuestros servicios de gestión de activos y corretaje para aprovechar ese crecimiento.</h5>
          <h5></h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
