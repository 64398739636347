import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Info from "components/Typography/Info.jsx";

import sectionSimilarStoriesStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionSimilarStoriesStyle.jsx";
// Default image
import defaultImage from "assets/img/Kinos/Properties/default_image.jpg";

function SectionDestacadas({ ...props }) {
  const { classes } = props;
  var hotels = props.featured.map((hotel) => {
    let url = '/mall/' + hotel.id;
    let images = hotel.attributes.images;

    if (typeof images !== 'undefined' && images.length > 0) {
      var image = hotel.attributes.images[0].attributes.adjunto_medium;
     } else {
      var image = defaultImage;
    }

    return(
      <GridItem xs={12} sm={4} md={4}>
        <Card blog>
          <CardHeader image>
            <Link to={url}>
              <img src={`https://wsrv.nl/?url=${image}`} alt="..." />
            </Link>
            <div
              className={classes.coloredShadow}
              style={{
                backgroundImage: `url(https://wsrv.nl/?url=${image})`,
                opacity: "1"
              }}
            />
          </CardHeader>
          <CardBody>
            <Info>
              <h6>REF: {hotel.attributes.ref}</h6>
            </Info>
            <h4 className={classes.cardTitle}>
              <Link to={url}>
                {hotel.attributes.name}
              </Link>
            </h4>
            <p className={classes.description}>
              {hotel.attributes.short_description}
              <Link to={{
                          pathname: url,
                          state: {
                            hotel: hotel.attributes
                          }
                        }}>
                           Read More
              </Link>
            </p>
          </CardBody>
        </Card>
      </GridItem>

    )
  })

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={12}>
            <h2 className={classes.title + " " + classes.textCenter}>
              Other properties you may be interested
            </h2>
            <br />
            <GridContainer>
              {hotels}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

export default withStyles(sectionSimilarStoriesStyle)(SectionDestacadas);
