import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import DoneOutline from "@material-ui/icons/DoneOutline";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.jsx";

function SectionServices(props) {
  const { classes } = props;
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Cinco consideraciones para crear una family office</h2>
          <h5 className={classes.description}>
            Debido a la creciente complejidad de administrar, proteger y aumentar el patrimonio familiar, las oficinas familiares han evolucionado para abordar nuevos desafíos y necesidades
          </h5>


        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="PRIMERO"
            description={
              <span>
                <p>
                  <ul>
                  <li>Proteger el patrimonio familiar asegurándose de que esté estructurado de acuerdo con la legislación y los requisitos reglamentarios pertinentes.</li>
                  <li>Gestión patrimonial global para clientes de alto patrimonio.</li>
                  <li>Amplíe su horizonte de riqueza familiar.</li>
                  </ul>
                  El patrimonio familiar en todos los niveles debe organizarse de manera eficiente para una buena gestión, eficiencia fiscal y planificación futura. La gestión del patrimonio familiar es un proceso que se basa en la combinación de la planificación financiera y la gestión de activos. Consideramos que los dos son componentes inseparables de una estrategia exitosa a largo plazo. En Kinos, cada familia con la que trabajamos es única, ya que sus metas están definidas por sus experiencias de vida y circunstancias familiares.
                </p>

              </span>
            }
            icon={DoneOutline}
            iconColor="green"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="SEGUNDO"
            description={
              <span>
                <p>
                  Distribuir el riesgo de inversión mediante la diversificación del patrimonio familiar Le ayudamos a desarrollar el plan financiero adecuado y las estrategias de inversión correspondientes para lograr sus objetivos. Diversificar: la forma inteligente de ahorrar e invertir diversificación: distribuir su dinero entre diferentes tipos de inversiones (denominadas 'clases de activos') y diferentes tipos de productos de inversión: ayuda a reducir el riesgo de sus inversiones generales (denominadas su 'cartera') bajo rendimiento o pérdida de dinero. "Las fortunas se hacen a través de la concentración y se mantienen mediante la diversificación", dice un viejo adagio de la empresa familiar.
                </p>

              </span>
            }
            icon={DoneOutline}
            iconColor="success"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="TERCERO"
            description={
              <span>
                <p>
                  Mejorar la cohesión familiar y la transición generacional al permitir el debate dentro de la familia sobre la asignación estratégica de activos y las decisiones clave de inversión. Posicionamiento de nuestra asignación estratégica objetivo de activos financieros e inmobiliarios en relación con la gama de posibles carteras. La relación entre una familia de patrimonio neto ultra alto y su family office unifamiliar es una sociedad compleja que debe sobrevivir a muchas fases, transiciones y desafíos. Una forma de entender las oficinas familiares es observar su evolución a través de las generaciones a medida que conectan a miembros de la familia dispares y administran inversiones complejas.
                </p>

              </span>
            }
            icon={DoneOutline}
            iconColor="info"
          />
        </GridItem>

        <GridItem md={6} sm={6}>
          <InfoArea
            title="CUARTO"
            description={
              <span>
                <p>
                  Aclarar la gobernanza sobre cómo la familia administra su patrimonio mediante el establecimiento de procesos adecuados para administrar los activos. El Family Office representa una alianza de colaboración entre tres grupos de partes interesadas: las generaciones familiares mayores y más jóvenes y los profesionales que dirigen la oficina. El desarrollo exitoso a largo plazo se basa en una colaboración eficaz entre estos grupos. Las generaciones mayores establecen una base de valores y prácticas heredados para la familia, junto con el liderazgo, la tutoría y la estabilidad. No obstante, deben transferir el poder con el tiempo a sus hijos adultos que están madurando y a sus asesores que no son familiares
                </p>

              </span>
            }
            icon={DoneOutline}
            iconColor="danger"
          />
        </GridItem>

        <GridItem md={6} sm={6}>
          <InfoArea
            title="QUINTO"
            description={
              <span>
                <p>
                  Gobernanza estratégica, para la toma de decisiones. Todas las organizaciones, desde family offices unifamiliares hasta empresas multinacionales.
                  <ul>
                    <li>Promueve el propósito compartido de la familia y ayuda a la oficina a lograr la visión de éxito de la familia.</li>
                    <li>Puede ampliarse o reducirse de acuerdo con la complejidad de la familia, los activos, los clientes y los servicios.</li>
                    <li>Crea responsabilidad y, por lo tanto, garantiza que el Family Office cumpla con las leyes y regulaciones pertinentes.</li>
                    <li>La estructura de gobierno ayuda a gestionar el riesgo y la complejidad a la vez que promueve la transparencia y la toma de decisiones eficiente.</li>
                    <li>La estructura funciona según lo diseñado incluso en momentos de estrés y conflicto extremos.</li>
                  </ul>
                </p>

              </span>
            }
            icon={DoneOutline}
            iconColor="warning"
          />
        </GridItem>


      </GridContainer>
    </div>
  );
}



export default withStyles(servicesStyle)(SectionServices);
