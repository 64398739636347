import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >

          <h2 className={classes.description, classes.textCenter}>

Compra y venta de inmuebles
          </h2>
          <h3>Compra, negocia, vende, invierte, crece, gana</h3>
          <h5>
            Aproveche la presencia de Kinos y Torus Network en España, Portugal y más de 120 países para satisfacer sus necesidades inmobiliarias comerciales, industriales y residenciales.
          </h5>
          <h5>Ya sea que desee adquirir o vender activos para cumplir con su inversión u objetivos operativos,
            lo conectamos directamente con el principal responsable de la toma de decisiones.  </h5>

          <h2 className={classes.description, classes.textCenter}>
            Obtenga acuerdos de inversión y coinversión únicos
          </h2>
          Cumpla sus objetivos de inversión con acceso directo a empresas que buscan capital y fondos que buscan LP en más de España, Portugal y más de 100 países.
          Establezca sus preferencias de tamaño de negocio, geografía y sector para cumplir con su mandato de inversión.
            <h2 className={classes.description, classes.textCenter}>
            Encuentre compradores para su activo o negocio
            </h2>
            Más 100.000 Clientes están con nosotros.
            Cuando decide vender sus activos o su empresa, encontrar el comprador adecuado es fundamental para el éxito continuo de su empresa, empleados y otras partes interesadas.
            Publique su oportunidad de forma anónima y sea emparejado directamente con compradores interesados.

        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
