import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description, classes.textCenter}>
            Principios comerciales: integridad, calidad, valores
          </h2>
          <h3>El éxito comienza con la construcción de relaciones</h3>
          <h5>
            El objetivo principal es la construcción de relaciones a largo plazo basadas en el saber hacer, la confianza y la confidencialidad con los principales actores de cada sector.
            Estamos dispuestos a compartir nuestro conocimiento y experiencia del mercado con usted para satisfacer sus objetivos.
          </h5>

        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
