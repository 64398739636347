import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// import Separator from "@material-ui/icons/LinearScale";
import Bullet from "@material-ui/icons/DragIndicator";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.jsx";

function SectionServices(props) {
  const { classes } = props;
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Lo que ofrecemos</h2>
          <h5 className={classes.description}>
            La cultura de asociación de Kinos, nuestro compromiso con la excelencia y la integridad intransigente nos convierte en una excelente opción para inversores con una visión a largo plazo.
          </h5>

          <h5 className={classes.description}>
            El interés de nuestro cliente primero es el núcleo de nuestra filosofía de gestión de activos.
              También adaptamos sus estrategias de inversión a las necesidades específicas de los clientes.
          </h5>
          <h5 className={classes.description}>Desarrollamos ideas y análisis que impulsan nuevas perspectivas, nuevas oportunidades y nuevos caminos de crecimiento.</h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="INTEGRIDAD"
            description={
              <span>
                <p>

La honestidad y la integridad son fundamentales para todo lo que hacemos.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="RESPONSABILIDAD"
            description={
              <span>
                <p>
                  Demostramos compromiso, cariño y entusiasmo.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="CAMARADERÍA"
            description={
              <span>
                <p>

El éxito de nuestros clientes y socios es nuestro enfoque incansable.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="EXCELENCIA"
            description={
              <span>
                <p>
                  Nos esforzamos por lograr una ejecución impecable.

                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>

        <GridItem md={6} sm={6}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >
          <h3 className={classes.title}>Estrategias de inversión</h3>
            <span>
              <p>
                <h5>Gestión de JV o Proceso de Desarrollo</h5>
                <h5>Modelado operativo financiero</h5>
                <h5>Presupuesto y control</h5>
                <h5>Estructura y especificaciones legales</h5>
                <h5>Interacción habilitada por la tecnología</h5>
                <h5>Informe financiero</h5>
                <h5>Informe operativo</h5>
                <h5>Estrategia de Marketing</h5>
                <h5>Estratgias de venta</h5>
                <h5>Análisis de costes del ciclo de vida</h5>
                <h5>Posicionamiento de activos</h5>
                <h5>Estrategias de salida</h5>
              </p>

            </span>
        </GridItem>

        <GridItem md={6} sm={6}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >
          <h3 className={classes.title}>Canales de inversión</h3>
            <span>

                <h6>Inversiones directas</h6>
                <h5>En activos inmobiliarios </h5>
                <h5>En proyectos</h5> 
                <h6>A través de intermediarios</h6>
                <h5>Gestión de inversiones </h5>
                <h5>Fondos de capital privado </h5>
                <h5>Fondos de infraestructura</h5> 
                <h5>Socimis</h5> 
                <h5>Reits</h5> 
                <h6>Otros vehículos de inversión conjunta...</h6>

            </span>
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(servicesStyle)(SectionServices);
