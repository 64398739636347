import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Separator from "@material-ui/icons/LinearScale";
import Bullet from "@material-ui/icons/DragIndicator";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.jsx";

function SectionServices(props) {
  const { classes } = props;
  return (
    <div className={classes.services}>


      <GridContainer>

        <GridItem md={4} sm={4}>
          <InfoArea
            title="Servicios de asesoría de inversiones."
            description={
              <span>
                <p>
                  El equipo de Servicios de asesoría de inversiones brinda una gama de servicios para ayudar a nuestros clientes a determinar la estrategia y ejecutar transacciones que involucran propiedades de inversión importantes.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>

        <GridItem md={4} sm={4}>
          <InfoArea
            title="Inversiones Metropolitanas."
            description={
              <span>
                <p>
                  Especializada en la venta de pequeñas y medianas inversiones en áreas metropolitanas y regionales de España, Portugal, Latinoamerica y Europa.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>

        <GridItem md={4} sm={4}>
          <InfoArea
            title="Ventas de propiedades comerciales"
            description={
              <span>
                <p>

Kinos ofrece un servicio global totalmente integrado que combina ventas de inversión, asesoría, financiamiento y banca hipotecaria,
                   junto con investigaciones líderes en la industria para conectar a nuestros clientes con compradores.

                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>



      </GridContainer>

      <GridContainer>

        <GridItem md={12} sm={12}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >

          <Separator /><Separator /><Separator />
          <h3 className={classes.title}>Instituciones con las que trabajamos</h3>
        </GridItem>

        <GridItem md={6} sm={6}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >

            <span>
              <p>
                <h5> Bancos </h5>
                <h5> Compañías de seguros </h5>
                <h5> Organizaciones benéficas </h5>
                <h5> Fondos de fondos de cobertura </h5>
                <h5> Oficinas familiares </h5>
                <h5> Gobiernos </h5>
              </p>
            </span>
        </GridItem>



        <GridItem md={6} sm={6}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >

            <span>
              <p>
                <h5> Bancos de inversión </h5>
                <h5> Fondo de pensiones </h5>
                <h5> Capital privado </h5>
                <h5> SWF (fondos soberanos de inversión) </h5>
                <h5> Socimis (fondos REIT) </h5>
                <h5> UHNWI </h5>
              </p> 
            </span>
        </GridItem>

      </GridContainer>
    </div>
  );
}



export default withStyles(servicesStyle)(SectionServices);
