import React from "react";
import ReactMarkdown from "react-markdown";
import Badge from "components/Badge/Badge.jsx";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import Quote from "components/Typography/Quote.jsx";
// core components

// import SectionCarousel from "./SectionCarousel.jsx";
import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";

function SectionText({ ...props }) {
  const { classes } = props;
  const { property } = props;
  // const imgClasses = classNames(
  //   classes.imgRaised,
  //   classes.imgRounded,
  //   classes.imgFluid
  // );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">



        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            {property.attributes.title}
          </h3>



          <p>
            <ReactMarkdown source={property.attributes.content} />
          </p>

          <div className={classes.blogTags}>
            Tags:
            { property.attributes.tags.map((tag) =>
              <Badge color="primary">{tag.name}</Badge>
            )}
          </div>

          <p></p>

        </GridItem>

      </GridContainer>


    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
