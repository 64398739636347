import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// import Separator from "@material-ui/icons/LinearScale";
import Bullet from "@material-ui/icons/DragIndicator";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.jsx";

function SectionServices(props) {
  const { classes } = props;
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Lo que ofrecemos</h2>
          <h5 className={classes.description}>
            La cultura de asociación de Kinos, nuestro compromiso con la excelencia y la integridad intransigente nos convierte en una excelente opción para inversores con una visión a largo plazo.
          </h5>

          <h5 className={classes.description}>
            El interés de nuestro cliente primero es el núcleo de nuestra filosofía de gestión de activos.
              También adaptamos sus estrategias de inversión a las necesidades específicas de los clientes.
          </h5>
          <h5 className={classes.description}>
            Desarrollamos ideas y análisis que impulsan nuevas perspectivas, nuevas oportunidades y nuevos caminos de crecimiento.</h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="PROPIETARIOS"
            description={
              <span>
                <p>
                  Ayudamos a realizar el potencial de sus activos inmobiliarios al ayudarlo con:
                  * Decisiones de desarrollo
                  * Gestión de arrendamientos
                  * Decisiones y procesos de compra
                  * Decisiones y procesos de venta
                  Nuestros profesionales de arrendamiento brindan experiencia estratégica a propietarios e inversionistas de propiedades comerciales, trabajando para:
                  * Maximizar el conocimiento en el mercado
                  * Estabilizar la ocupación mediante el arrendamiento y la retención de inquilinos
                  * Conseguir rentas óptimas
                  * Mejorar el valor de la inversión
                  Nuestro doble objetivo es lograr los objetivos inmobiliarios y los criterios de inversión del cliente y desarrollar relaciones que resulten en asociaciones a largo plazo.
</p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="INQUILINOS"
            description={
              <span>
                <p>
                  Nuestros expertos ayudan a los inquilinos a identificar objetivos estratégicos comerciales, financieros y operativos, y alinearlos con los requisitos inmobiliarios mediante el uso de nuestro conocimiento profundo de los mercados globales, habilidades estratégicas de resolución de problemas y experiencia.
                  Los servicios de representación de inquilinos incluyen:
                  * Desarrollo de estrategias
                  * Planificación a medida
                  * Análisis demográfico
                  * Servicios de logística / distribución
                  * Análisis de mercado
                  * Análisis de costo de ocupación
                  * Negociaciones
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="INVERSORES"
            description={
              <span>
                <p>
                  Si está buscando una oportunidad de inversión inmobiliaria, contáctenos aquí. Puede reunirse con un corredor para estos tipos específicos de inversiones inmobiliarias y discutir qué tipo de criterios y rendimiento está buscando. Si está buscando vender propiedades de inversión, un corredor de bienes raíces también puede ayudarlo con eso.</p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="INTERMEDIACIÓN"
            description={
              <span>
                <p>
                  Relaciones de intermediación y asesoría de inversiones.
                  El mundo del corretaje está cambiando, pero siguen siendo más importantes las relaciones.
                  Formando confianza
                  La confianza es la base de toda relación comercial y los bienes raíces no son una excepción. En el lado de la compra de vivienda, la Asociación Nacional de Agentes Inmobiliarios descubrió que la honestidad y la confianza eran los factores principales para los compradores al elegir con qué agente trabajar; Lo mismo ocurre con los inmuebles comerciales.
</p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>

        <GridItem md={6} sm={6}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >
          <h3 className={classes.title}>Estrategias de inversión</h3>
            <span>
              <p>
                <h5>Gestión de JV o Proceso de Desarrollo</h5>
                <h5>Modelado operativo financiero</h5>
                <h5>Presupuesto y control</h5>
                <h5>Estructura y especificaciones legales</h5>
                <h5>Interacción habilitada por la tecnología</h5>
                <h5>Informe financiero</h5>
                <h5>Informe operativo</h5>
                <h5>Estrategia de Marketing</h5>
                <h5>Estratgias de venta</h5>
                <h5>Análisis de costes del ciclo de vida</h5>
                <h5>Posicionamiento de activos</h5>
                <h5>Estrategias de salida</h5>
              </p>

            </span>
        </GridItem>

        <GridItem md={6} sm={6}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >
          <h3 className={classes.title}>Canales de inversión</h3>
            <span>

                <h6>Inversiones directas</h6>
                <h5>En activos inmobiliarios </h5>
                <h5>En proyectos</h5> 
                <h6>A través de intermediarios</h6>
                <h5>Gestión de inversiones </h5>
                <h5>Fondos de capital privado </h5>
                <h5>Fondos de infraestructura</h5> 
                <h5>Socimis</h5> 
                <h5>Reits</h5> 
                <h6>Otros vehículos de inversión conjunta...</h6>

            </span>
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(servicesStyle)(SectionServices);
