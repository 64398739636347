import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
// import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import FooterLinks from "components/Footer/FooterLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.jsx";

// Consent
import CookieConsent, { Cookies } from "react-cookie-consent";

import { HashLink } from 'react-router-hash-link';

// Sections for this page
import SectionProduct from "./Sections/SectionProduct.jsx";
import SectionWork from "./Sections/SectionWork.jsx";
import SectionPricing from "./Sections/SectionPricing.jsx";
// Footer
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

class LandingPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          brand="Kinos Corp"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "info"
          }}
          {...rest}
        />
      <Parallax image={require("assets/img/Kinos/home.jpeg")} filter="dark">
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <h1 className={classes.title}>Inmobiliaria comercial y residencial</h1>
                <h4>
                  Conectando inversores con oportunidades de inversión.
    Gestión de activos inmobiliarios familiares o empresariales
                </h4>
                <br />
                <Link to="/offer" className={classes.dropdownLink}>
                  <Button
                    color="danger"
                    size="lg"
                  >
                    <i className="fas fa-play" />
                    Explore nuestras ofertas
                  </Button>
                </Link>
                <Link to="/demand" className={classes.dropdownLink}>
                  <Button
                    color="danger"
                    size="lg"
                  >
                    <i className="fas fa-play" />
                      Explore nuestra demanda de activos
                  </Button>
                </Link>
              </GridItem>
              <GridItem
                  md={12}
                  sm={12}
                  className={classNames(
                    classes.mlAuto,
                    classes.mrAuto,
                    classes.textCenter
                  )}
              >
                  <HashLink to="/properties#retail" smooth><Button color="rose" size="sm" round>VIVIENDAS</Button></HashLink>
                  <HashLink to="/properties#industrial" smooth><Button color="rose" size="sm" round>INDUSTRIAL</Button></HashLink>
                  <HashLink to="/properties#offices" smooth><Button color="rose" size="sm" round>OFICINAS</Button></HashLink>
                  <HashLink to="/properties#buildings" smooth><Button color="rose" size="sm" round>EDIFICIOS</Button></HashLink>
                  <HashLink to="/properties#hotels" smooth><Button color="rose" size="sm" round>HOTELES</Button></HashLink>
                  <HashLink to="/properties#malls" smooth><Button color="rose" size="sm" round>CENTROS COMERCIALES</Button></HashLink>
                  <HashLink to="/properties#residential" smooth><Button color="rose" size="sm" round>RESIDENCIAL</Button></HashLink>
                  <HashLink to="/properties#n_d" smooth><Button color="rose" size="sm" round>OBRANUEVA</Button></HashLink>
                  <HashLink to="/properties#lands" smooth><Button color="rose" size="sm" round>SUELOS</Button></HashLink>
                  <HashLink to="/properties#rural_lands" smooth><Button color="rose" size="sm" round>FINCAS</Button></HashLink>
                  <HashLink to="/properties#investments" smooth><Button color="rose" size="sm" round>INVERSIONES</Button></HashLink>
                  <HashLink to="/properties#ops" smooth><Button color="rose" size="sm" round>OPORTUNIDADES</Button></HashLink>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>

            <SectionProduct />
            <SectionPricing />

            <SectionWork />
          </div>
        </div>

        <br></br><br></br>

        <Footer>
          <FooterLinks/>
        </Footer>
        <CookieConsent location="bottom" cookieName="AcceptCookie" expires={90} overlay buttonText="Entiendo y acepto" cookieName="myAwesomeCookieName2">
          Este sitio web utiliza cookies para mejorar la experiencia del usuario.
        </CookieConsent>
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(LandingPage);
