import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
// core components

import SectionDestacadas from "KComponents/SectionDestacadas.jsx";
import SectionRecents from "KComponents/SectionRecents.jsx";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";

function SectionText({ ...props }) {
  const { classes } = props;
  const { featured } = props;
  const { recents } = props;

  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            Kinos proporciona un amplio espectro de asesoramiento técnico sobre edificación y edificación
          </h3>
          <p>

cubriendo todos los aspectos de la evaluación, desarrollo, ocupación, mantenimiento y adecuación de activos inmobiliarios de todo tipo.
          </p><p>
Trabajamos en estrecha colaboración con nuestros clientes a lo largo de cada asignación, escuchando lo que desea lograr y encontrando con frecuencia formas nuevas o innovadoras de cumplir. Para asignaciones complejas, creamos y lideramos equipos de proyectos efectivos, trabajando en estrecha colaboración con nuestros propios colegas y con consultores externos. Esta combinación de habilidad de base amplia con la riqueza de nuestra experiencia garantiza que siempre vea la imagen completa y pueda tomar decisiones completamente informadas.
</p><p>

Desarrollo comercial y asesoramiento estratégico
</p><p>
WPodemos ayudar en el análisis de cualquier cartera inmobiliaria. Podemos dirigir funciones como asignaciones provisionales y brindar asesoramiento estratégico cuando sea necesario. Podemos ser su unidad de desarrollo de proyectos desde el concepto hasta la entrega.
</p><p>

Las devoluciones tienen prioridad. Consejos rápidos y sólidos
Estos factores son esenciales para los procesos de toma de decisiones para las transacciones de compra y venta, la prestación de servicios de contratos de arrendamiento, las cuestiones de sostenibilidad y la optimización de las características de una propiedad.
          </p>
          <Quote
            textClassName={classes.quoteText}
            text="“La excelencia es nuestro objetivo, la satisfacción de los clientes es nuestro orgullo.”"
            author="Joaquín Morales, CEO."
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={10} className={classes.section}>
          <SectionDestacadas featured= {featured} url="building" />
          <SectionRecents recents= {recents} url="building" />
        </GridItem>

        <GridItem xs={12} sm={8} md={8} id="search" >
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
