import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
// core components

import SectionDestacadas from "KComponents/SectionDestacadas.jsx";
import SectionRecents from "KComponents/SectionRecents.jsx";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";

function SectionText({ ...props }) {
  const { classes } = props;
  const { featured } = props;
  const { recents } = props;
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            Nuestro dedicado equipo de investigación rural ofrece una gama de soluciones de consultoría originales y personalizadas a clientes de todas las disciplinas rurales, desde la administración de propiedades y el desempeño de activos hasta la evaluación de políticas rurales y la estrategia de agronegocios.
          </h3>
          <p>


            Trabajando predominantemente a nivel nacional, pero también a nivel mundial, nuestro equipo central trabaja a la vanguardia de todas las áreas rurales, proporcionando las últimas tendencias del mercado y análisis de los sectores relacionados con la energía, las propiedades, la alimentación y la agricultura, la silvicultura, el ocio, los minerales y la gestión de residuos. Muchos de nuestros analistas experimentados también tienen experiencia agrícola práctica, lo que brinda una experiencia invaluable al mismo tiempo que se basa en sus conexiones con profesionales y equipos de investigación en todos los sectores dentro de la red global de Kinos & Torus y más allá.
            <br /> <br />
            Cada cliente es tratado individualmente, y los analistas adaptan su trabajo a los objetivos de los clientes. Nuestros campos de especialización pueden cubrir todas las bases dependiendo de lo que se requiera, incluyendo evaluación de riesgos, análisis estratégico, benchmarking, gestión de datos, previsión, estudios de viabilidad, encuestas, rentas rurales, inversión y desempeño de activos. Nuestros clientes incluyen administradores de fincas agrícolas y rurales, públicos y privados, instituciones e inversionistas, bancos y administradores de fondos inmobiliarios, agencias gubernamentales, corporaciones y la cadena de suministro (entrada y salida) desde agricultores hasta minoristas.

          </p>
          <Quote
            textClassName={classes.quoteText}
            text="“¡La excelencia es nuestro objetivo, la satisfacción de los clientes es nuestro orgullo!”"
            author="Joaquín Morales, CEO."
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={10} className={classes.section}>
          <SectionRecents recents={recents}  url="rural_land" />
          <SectionDestacadas featured={featured} url="rural_land" />
        </GridItem>

        <GridItem xs={12} sm={8} md={8} id="search" >
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
