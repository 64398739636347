import React from "react";
// nodejs library that concatenates classes

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// office

import description from "assets/img/bg.jpg";

function SectionOffice(props) {
  const { classes } = props;
  return (
    <div className={classes.office}>

      <GridContainer>

        <GridItem xs={12} sm={12} md={12}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + description + ")" }}
          >
            <CardBody background>
              <h6 className={classes.category}>Servicios y Marketing</h6>
              <a href="#">
                <h3 className={classes.cardTitle}>
                  Ofrecemos un asesoramiento integral diseñado para cada cliente según las necesidades de cada proyecto.
                </h3>
              </a>
              <p className={classes.category}>

Agencia de servicios,
                mercados de capitales,
                Consultoría,
                Finanzas Corporativas y Deuda,
                Planificación,
                Ad inistración de propiedad,
                Investigación,
                Representación de inquilinos,
                Valoración y tasación
              </p>

            </CardBody>
          </Card>
        </GridItem>




      </GridContainer>
    </div>
  );
}

export default withStyles(officeStyle)(SectionOffice);
