import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// import Separator from "@material-ui/icons/LinearScale";
import Bullet from "@material-ui/icons/DragIndicator";
// core components
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.jsx";

function SectionServices(props) {
  const { classes } = props;
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Lo que hacemos</h2>
          <h5 className={classes.description}>
            Kinos brinda servicios a usuarios, propietarios, inquilinos, inversionistas y desarrolladores de bienes raíces comerciales y residenciales; incluyen corretaje,
            marketing y ventas, consultoría, servicios a oficinas familiares, servicios de inversión, representación de propietarios e inquilinos, gestión de proyectos,
            planificación urbana, gestión de propiedades y activos, valoración y servicios de asesoramiento.
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="INTERMEDIACIÓN COMPRA/VENTA"
            description={
              <span>
                <p>
                  <h5>
                    Descubra lo que aportan los servicios de corretaje de bienes raíces de Kinos.</h5>
                  <h5>
                    Kinos trabaja para proporcionar el mejor retorno de la inversión a los clientes que buscan vender o arrendar propiedades.
                  </h5>
                  <h5>
                    Ayudamos a los inversores a identificar las propiedades para compra o arrendamiento que se adapten mejor a su situación y objetivos.
                  </h5>
                  <h5>
                    Kinos conecta a los inversores con opciones de financiación creativas que se adaptan a necesidades muy específicas y estructura transacciones que minimizan
                    los costos fiscales y maximizan los beneficios fiscales.
                    También ofrecemos servicios adicionales, como administración de propiedades, proyectos y carteras, tasación y desarrollo de propiedades y consultoría comercial.{" "}
                  </h5>
                </p>
              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="SERVICIOS DE FAMILY OFFICE"
            description={
              <span>
                <p>
                  <h5>
                    Un enfoque institucional para la gestión del patrimonio familiar a lo largo de generaciones.
                    <Link to="/family-office">Kinos Family Office</Link> ofrece servicios de asesoría integral para familias y organizaciones sobre sus activos
                      (financieros, inmobiliarios, proyectos y participaciones societarias), con el fin de preservar su patrimonio en el tiempo y con un enfoque global e independiente.
                    Gestión y fomento del patrimonio familiar
                    <ul>
                    <li> Mandato de Family Office para servicios de asesoría en todos los activos del cliente.</li>
                    <li> Proyectos de consultoría para la planificación estratégica del patrimonio familiar.</li>
                    <li> Asesoramiento y apoyo en la creación de una unifamiliar.</li>
                    </ul>
                    Evaluación del patrimonio familiar.
                    FOMENTO DE EMPRESAS FAMILIARES. Premiar la excelencia.{" "}
                  </h5>
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="NUEVAS PROMOCIONES MARKETING Y VENTA"
            description={
              <span>
                <p>
                  <h5>
                    Este exclusivo servicio de consultoría de viviendas nuevas proporciona a los promotores inmobiliarios un equipo dedicado con un conocimiento profundo,
                    sin duda inigualable por otras empresas de marketing inmobiliario.
                    Estamos preparados para redefinir la industria con servicios, mercados y sistemas más sólidos para el mejoramiento de los clientes.
                    Se ofrecen modelos de servicios especializados para cada tipo de producto,
                    desde unifamiliares hasta grandes comunidades de planes maestros de uso mixto,
                    mientras que el cumplimiento de un ciclo de vida de desarrollo estructurado garantiza el éxito del proyecto de principio a fin.{" "}
                  </h5>
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="GESTIÓN DE ACTIVOS "
            description={
              <span>
                <p>
                  <h5>
                    La gestión de la cartera exige conocimiento del mercado inmobiliario, comprensión de las necesidades y requisitos financieros de los inversores,
                    expectativas de los inquilinos y experiencia en la tecnología y la estructura del edificio.
                    Con nuestro amplio conocimiento del mercado recopilado de todas las propiedades analizadas en las líneas de servicios complementarios,
                    Kinos posee experiencia interna para todos los asuntos inmobiliarios.
                    Los inversores en España y en todo el mundo nos consideran uno de los asesores de cartera más eficaces.
                    Esta reputación nos da una gran credibilidad en todos los procesos del ciclo de vida de las propiedades y las carteras para los propietarios.{" "}
                  </h5>
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(servicesStyle)(SectionServices);
