import React from "react";
import PropTypes from 'prop-types';
import axios from 'axios';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
// @material-ui/icons
import Search from "@material-ui/icons/Search";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Footer from "components/Footer/Footer.jsx";
import FooterLinks from "components/Footer/FooterLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
// sections for this page
import SectionText from "./Sections/SectionText.jsx";
import SectionResults from "./Sections/SectionResults.jsx";
import ModalFilter from "./Sections/ModalFilter.jsx";
import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.jsx";

class HotelsPage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      hotelList: [],
      featured: [],
      recents: [],
      per_page: 15,
      total: 0,
      formControls: {
        min_price: '0',
        max_price: '0',
        name: '',
        sup_max: '',
        sup_min: '',
        zone: ''
        }
      };
    }

  handleViewMoreResults = () => {

      let per_page = this.state.per_page;
      let total = this.state.total;

      per_page = +per_page+6;

      if (per_page > total) { per_page = total; }

      let url = 'https://api.kinosgroup.com/api/v1/es/hotels?per_page=' + per_page ;

      axios
        .get(url)
        .then(response => {
              if (this._isMounted) {
              this.setState({ hotelList: response.data.data });
              this.setState({ per_page });
              console.log(response.data.data);
              }
          })
          .catch(errors => {
            console.error(errors);
          });

    };

    handleChange = (e) => {
        /*
          Because we named the inputs to match their
          corresponding values in state, it's
          super easy to update the state
        */
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
          formControls: {
              ...this.state.formControls,
              // ['subject']:  'I want ifo about the ref: ' + this.props.reference,
              [name]: value
            }
        });


        let url = 'https://api.kinosgroup.com/api/v1/es/hotels?per_page=' + this.state.per_page ;


        if (Number(this.state.formControls.min_price) > 0) {
          url += '&precio_min=' + this.state.formControls.min_price ;
        }

        if (Number(this.state.formControls.max_price) > 0) {
          url += '&precio_max=' + this.state.formControls.max_price ;
        }

        if (Number(this.state.formControls.sup_min) > 0) {
          url += '&superficie_min=' + this.state.formControls.sup_min ;
        }

        if (Number(this.state.formControls.sup_max) > 0) {
          url += '&superficie_max=' + this.state.formControls.sup_max ;
        }

        if (this.state.formControls.name != '') {
          url += '&query=' + this.state.formControls.name ;
        }

        if (this.state.formControls.name != '') {
          url += '&zone=' + this.state.formControls.zone ;
        }

        axios
          .get(url)
          .then(response => {
                if (this._isMounted) {
                this.setState({ hotelList: response.data.data });
                this.setState({ total: response.data.meta.total} );
                console.log(response.data.data);
                }
            })
            .catch(errors => {
              console.error(errors);
            });

      };

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    let urlResults = 'https://api.kinosgroup.com/api/v1/es/hotels?per_page=' + this.state.per_page ;
    let urlFeatured = 'https://api.kinosgroup.com/api/v1/es/hotels?per_page=3&sort=categoria&direction=desc' ;
    let urlRecents = 'https://api.kinosgroup.com/api/v1/es/hotels?per_page=3&sort=updated_at&direction=desc' ;

    const requestResults = axios.get(urlResults);
    const requestFeatured = axios.get(urlFeatured);
    const requestRecents = axios.get(urlRecents);

    axios
      .all([requestResults, requestFeatured, requestRecents])
      .then(
          axios.spread((...responses) => {
            const responseResults = responses[0];
            const responseFeatured = responses[1];
            const responseRecents = responses[2];

            if (this._isMounted) {
              this.setState({ hotelList: responseResults.data.data });
              this.setState({ featured: responseFeatured.data.data });
              this.setState({ recents: responseRecents.data.data });
              this.setState({ total: responseResults.data.meta.total} );
              this.setState({ per_page: responseResults.data.meta.per_page} );
              console.log(responseResults.data.data, responseFeatured.data.data, responseRecents.data.data);
            }

          })
        )
        .catch(errors => {
          // react on errors.
          console.error(errors);
        });
}

  componentWillUnmount() {
    this._isMounted = false;
  }



  render() {
    const { classes } = this.props;
    const { hotelList, featured, recents, total, per_page } = this.state;
    return (
      <div>
        <Header
          brand="Kinos Corp"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "info"
          }}
        />
      <Parallax image={require("assets/img/Kinos/Properties/hotel.jpg")} filter="dark">
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem md={8} className={classes.textCenter}>
                <h1 className={classes.title}>
                  Hoteles
                </h1>
                <h4 className={classes.subtitle}>
                  Tenemos los siguientes hoteles en cartera.
                </h4>
                <br />
                <Link to="/hotels#search" smooth>
                  <Button color="rose" size="lg" round>
                    <Search /> Buscar
                  </Button>
                </Link>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classes.main}>

          <div className={classes.container}>
            <SectionText featured={featured} recents={recents} />
            <br/><hr/><br/>
            <ModalFilter handleChange={this.handleChange} formControls={this.state.formControls} total={this.state.total} />
            <SectionResults hotels={hotelList} total={total} perPage={per_page} handleViewMoreResults={this.handleViewMoreResults}/>
          </div>
        </div>

          <Footer>
            <FooterLinks/>
          </Footer>
      </div>
    );
  }
}

export default withStyles(blogPostPageStyle)(HotelsPage);
