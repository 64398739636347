/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import footerStyle from "assets/jss/material-kit-pro-react/components/footerStyle.jsx";

function Footer(props) {
  const { children, content, classes, theme, big, className } = props;
  const themeType =
    theme === "transparent" || theme == undefined ? false : true;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme]]: themeType,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined
  });
  const aClasses = classNames({
    [classes.a]: true
  });

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        {children !== undefined ? (
          <div>
            <div className={classes.content}>{children}</div>
            <hr />
          </div>
        ) : (
          " "
        )}
        <div>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <Link to="/" className={classes.block} >
                  Kinosgroup
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/about-us" className={classes.block} >
                  Acerca de nosotros
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/blog-posts" className={classes.block} >
                  Blog
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/privacy" className={classes.block} >
                  Privacidad
                </Link>
              </ListItem>
            </List>
          </div>
          <div className={classes.right}>
            &copy; {1900 + new Date().getYear()} , made with{" "}
            <Favorite className={classes.icon} /> by{" "}
            <a href="http://www.pasf-id.com" >PASF-ID</a> for a better web.
          </div>
        </div>
        <div className={classes.clearFix} />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  big: PropTypes.bool,
  content: PropTypes.node.isRequired
};

export default withStyles(footerStyle)(Footer);
