import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description, classes.textCenter}>
            SERVICIOS DE GESTIÓN E INTERMEDIACIÓN
          </h2>
          <h3>Intermediación en activos empresariales y comerciales</h3>
          <h5><strong>Kinos</strong> brinda servicios de corretaje e intermediación a inquilinos, vendedores, compradores, propietarios de activos inmobiliarios residenciales y comerciales. Nuestros servicios están diseñados para ayudar a maximizar el valor de su activo y para ayudarlo en cada paso del proceso de transacción. Ya sea que sea un propietario experimentado, un comprador por primera vez, un propietario de una cartera residencial o comercial, nuestro consejo siempre es personalizado y transparente. Nuestro sólido conocimiento del mercado, informado por investigaciones internas líderes en el sector, garantiza que reciba el mejor asesoramiento posible en todo momento, sean cuales sean sus necesidades.
          </h5>
          <h5>Nuestros servicios Servicios de asesoría de venta. Trabajamos tanto con particulares como con empresas corporativas, instituciones  y empresas familiares de propiedad privada o pública. 
Compra venta o alquiler de activos inmobiliarios. 
Compra venta de empresas.
Nuestros clientes suelen estar interesados en:
 Salidas de propietarios: Facilitar la salida de los propietarios de empresas; Adquisiciones gerenciales: ayudar a los gerentes clave a adquirir su empresa de sus propietarios actuales; Re-capitalizaciones: compra de acciones de accionistas actuales que buscan liquidez para agregar capital al balance o reducir la deuda; Consolidaciones de la industria: combinación de la experiencia operativa y las relaciones de las gerencias con nuevas fuentes de capital y experiencia para consolidar una industria fragmentada. Servicios de asesoramiento de compra Utilizamos un enfoque integral para ayudar a nuestros clientes a buscar y adquirir una empresa. Ayudamos en este proceso al: Trabajar con nuestro cliente para definir los criterios de búsqueda; Utilizar bases de datos y contactos en la industria para identificar a los mejores candidatos; Iniciar la comunicación con los posibles objetivos y realizar la diligencia debida preliminar para determinar si el objetivo encaja bien; Analizar los datos para determinar el precio de oferta y la estructura óptima para la transacción; Gestionar el proceso de debida diligencia con nuestros clientes contadores y abogados; Cerrar la transacción. Valoraciones Tenemos una experiencia significativa en la valoración de empresas de propiedad privada y activos inmobiliarios tanto públicos como privados en una amplia gama de industrias, informando a los clientes del valor actual de su empresa o activos  mediante la realización de un análisis exhaustivo de su negocio, valoración de mercados así como evaluando las tendencias de la industria empresarial y mercado inmobiliario  junto con otros factores diversos.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
