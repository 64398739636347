import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
// core components
// import Badge from "components/Badge/Badge.jsx";

import SectionDestacadas from "KComponents/SectionDestacadas.jsx";
import SectionRecents from "KComponents/SectionRecents.jsx";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";

function SectionText({ ...props }) {
  const { classes } = props;
  const { featured } = props;
  const { recents } = props;

  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            Nuestro equipo de centro comercial y nuestros socios pueden brindarle una guía completa para adquirir y administrar este tipo de activo.
          </h3>
          <p>
            Esto incluye asesoramiento sobre cómo comprar y mejorar los activos del centro comercial, así como consultoría de agencia y arrendamiento.
Desde la gestión diaria hasta el asesoramiento de inversiones, somos conocidos por nuestra atención al detalle. Nos enorgullecemos de nuestra visión del mercado y aplicamos la experiencia específica del sector a todos los proyectos, trabajando para las principales instituciones, fondos y clientes privados.

Puede encontrar nuestros socios especializados en Madrid, Barcelona, ​​Málaga. Esta amplia cobertura nos permite invertir fuertemente en nuestros recursos y asegura un nivel de servicio amplio y consistente a nuestros clientes.
          </p>
          <Quote
            textClassName={classes.quoteText}
            text="“¡La excelencia es nuestro objetivo, la satisfacción de los clientes es nuestro orgullo!”"
            author="Joaquín Morales, CEO."
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={10} className={classes.section}>
          <SectionDestacadas featured= {featured} url="mall" />
          <SectionRecents recents= {recents} url="mall" />
        </GridItem>

        <GridItem xs={12} sm={8} md={8} id="search" >
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
