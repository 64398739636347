import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
// core components

import SectionDestacadas from "KComponents/SectionDestacadas.jsx";
import SectionRecents from "KComponents/SectionRecents.jsx";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";

function SectionText({ ...props }) {
  const { classes } = props;
  const { featured } = props;
  const { recents } = props;
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>

La misión de Kinos Land Services es acelerar el éxito de nuestros clientes a través de un conocimiento superior del mercado, tecnología líder en la industria y una perspectiva inmobiliaria global.
          </h3>
          <p>

Los profesionales de Kinos Land Services se especializan en la titularidad y disposición de tierras, ofreciendo a los clientes un amplio conocimiento de las condiciones del mercado local, parcelas de tierra competitivas y asuntos regulatorios, para ayudar a los propietarios de tierras a maximizar sus valores inmobiliarios. Los servicios de representación de propietarios, desarrolladores e inversores de terrenos incluyen análisis de mercado y estudios de viabilidad, selección de sitios estratégicos, análisis financiero, servicios demográficos y cartográficos y adquisiciones y enajenaciones de propiedades.


            <br /><br />
              Aprovechamos a los expertos en nuestras especialidades de mercado de capitales, valoración y asesoría y corretaje industrial, minorista y de oficinas para acelerar el éxito de nuestros clientes a través de un conocimiento superior del mercado, tecnología líder en la industria y una perspectiva inmobiliaria global.

            <br /><br />
              Kinos Land Services mantiene una base de datos de miles de inversionistas de tierras globales, individuos de alto patrimonio neto, inversionistas extranjeros y oficinas familiares para comercializar propiedades de manera efectiva y eficiente. Nuestros profesionales reúnen equipos de especialistas capacitados en diversas líneas de servicio para comercializar paquetes directamente a inversores y usuarios finales.

          </p>
          <Quote
            textClassName={classes.quoteText}
            text="“¡La excelencia es nuestro objetivo, la satisfacción de los clientes es nuestro orgullo!”"
            author="Joaquín Morales, CEO."
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={10} className={classes.section}>
          <SectionDestacadas featured= {featured} url="urban_land" />
          <SectionRecents recents= {recents} url="urban_land" />
        </GridItem>

        <GridItem xs={12} sm={8} md={8} id="search" >
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
