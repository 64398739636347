import React from "react";
import axios from 'axios';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import { Link } from "react-router-dom";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import TextField from '@material-ui/core/TextField';

// import sectionCommentsStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionCommentsStyle.jsx";
import sectionCommentsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ModalAsk extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      signupModal: false,

      formControls: {
        sender_name: '',
        sender_phone: '',
        sender_mail: '',
        message: '',
        subject: "Ask of active",
        website: "Kinoscorp EN - hotels",
        zone: ''


      }
    };
    this.onChange = this.onChange.bind(this);
  }

  //const [signupModal, setSignupModal] = React.useState(false);
  onChange = (e) => {
        /*
          Because we named the inputs to match their
          corresponding values in state, it's
          super easy to update the state
        */
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
          formControls: {
              ...this.state.formControls,
              ['subject']:  'I want ifo about the ref: ' + this.props.reference,
              [name]: value
            }
        });

      }

  onSubmit = (e) => {
        e.preventDefault();
        this.setState({signupModal: false}) ;
        // get our form data out of state
        const { formControls } = this.state;

        axios.post('https://api.kinosgroup.com/api/v1/en/messages/contact', { formControls })
          .then((result) => {
            //access the results here....
          });
      }

    openModal = (e) => {
      this.setState({signupModal: true}) ;
    }
    closeModal = (e) => {
      this.setState({signupModal: false}) ;
    }

  render () {
    const { classes } = this.props;
    const { formControls } = this.state;

  return (
    <div>
        <div>
        <Button color="danger" size="lg" onClick={this.openModal} >
          <i className="fas fa-paper-plane" />Pedir más información
        </Button>
        </div>


        <Dialog
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSignup
            }}
            open={this.state.signupModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.closeModal}
            aria-labelledby="signup-modal-slide-title"
            aria-describedby="signup-modal-slide-description"
          >
            <Card plain className={classes.modalSignupCard}>
              <DialogTitle
                id="signup-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  color="primary"
                  simple
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={this.closeModal}
                >
                  {" "}
                  <Close className={classes.modalClose} />
                </Button>

              </DialogTitle>
              <DialogContent
                id="signup-modal-slide-description"
                className={classes.modalBody}
              >
                <form className={classes.form}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
                  <div className={classes.textCenter}>
                    <h2 className={classes.socialTitle}>Pídanos más información sobre la propiedad</h2>
                  </div>
                  <h5>
                    Tenga en cuenta que cualquier dato personal que usted y otros proporcionen a través de esta herramienta pueden ser procesados ​​por Kinos. La protección de sus datos personales es muy importante para Kinos. Kinos procesará sus datos personales de conformidad con las leyes de protección de datos aplicables.
                    Su decisión de proporcionar sus datos personales a Kinos es voluntaria. Sin embargo, dado que ciertas funcionalidades de esta herramienta requieren datos personales para funcionar, las funcionalidades no funcionarán si decide no proporcionar sus datos personales.
                    Kinos le invita a leer atentamente la <Link to = "/ privacy"> política de privacidad </Link>, que incluye información importante sobre por qué y cómo Kinos está procesando sus datos personales.
                  </h5>
                  </GridItem>


                  <GridItem xs={12} sm={6} md={12} className={classes.mrAuto}>

                      <TextField
                          id="name"
                          label="Nombre"
                          name="sender_name"
                          className={classes.textField}
                          margin="normal"
                          fullWidth
                          value={formControls.sender_name}
                          onChange={this.onChange}
                        />

                  </GridItem>

                  <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>

                      <TextField
                          id="phone"
                          label="Teléfono"
                          name="sender_phone"
                          className={classes.textField}
                          margin="normal"
                          fullWidth
                          value={formControls.sender_phone}
                          onChange={this.onChange}
                        />





                  </GridItem>

                  <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>


                      <TextField
                          id="mail"
                          label="Correo electrónico"
                          name="sender_mail"
                          className={classes.textField}
                          margin="normal"
                          fullWidth
                          value={formControls.sender_mail}
                          onChange={this.onChange}
                        />





                  </GridItem>

                  <GridItem xs={12} sm={6} md={12} className={classes.mrAuto}>

                      <TextField
                          id="message"
                          label="Mensaje"
                          name="message"
                          className={classes.textField}
                          margin="normal"
                          fullWidth
                          multiline
                          value={formControls.message}
                          onChange={this.onChange}
                        />

                  </GridItem>

                  <div className={classes.textCenter}>
                    <Button round color="primary" onClick={this.onSubmit}>
                      <i className="fas fa-paper-plane" />Enviar
                    </Button>
                  </div>

                </GridContainer>
                    </form>
              </DialogContent>
            </Card>
          </Dialog>
      </div>
    );
  }
}

export default withStyles(sectionCommentsStyle)(ModalAsk);
