import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description, classes.textCenter}>
            EXPERTOS EN OBRA NUEVA
          </h2>

          <h3>Ventas impulsadas por Kinos y socios nacionales e internacionales.</h3>
          <h3>Inteligencia inmobiliaria. Conviértase en Inteligencia Inmobiliaria Descubra Kinos & Partners más Torus Network</h3>
          <h5>
            Conexión entre altos inversores inmobiliarios, promotores y entidades especializadas en financiación de proyectos inmobiliarios en España, Portugal y a nivel internacional.
Ventas y comercialización de proyectos en modelo de contrato exclusividad. Innovación en modelo Comercialización exclusiva más gestión colaborativa de proyectos inmobiliarios.
          </h5>

          <h5>
            Ventas y comercialización de proyectos en modelo de contrato exclusividad. Innovación en modelo Comercialización exclusiva más gestión colaborativa de proyectos inmobiliarios.
          </h5>
          <h3>APRENDA CÓMO KINOS Y TORUS ESTÁN GESTIONANDO VENTAS  POST COVID PARA PROPORCIONAR SEGURIDAD, TECNOLOGÍAS Y VENTAS EN EL NUEVO MERCADO, EL NUEVO MUNDO</h3>
          <h5>
          Kinos & Partners, es la unificación de dos potencias inmobiliarias: Marketing Solutions Kinos Group y <Link to="/register">Torus Real Estate Network</Link> Marketing Relations P2P, B2B, B2C. Como entidades separadas, las empresas se establecieron como líderes del mercado, ofreciendo servicios inmobiliarios distintivos a los desarrolladores. Juntos, como la empresa de marketing y ventas inmobiliarias más completa de España y Portugal, estamos preparados para redefinir la industria de las ventas inmobiliarias con servicios, mercados y sistemas más sólidos para la mejora de los clientes. Se ofrecen modelos de servicios especializados para cada tipo de producto, desde unifamiliares hasta grandes propiedades comerciales de uso mixto o comunidades de planes maestros, mientras que el cumplimiento de un ciclo de vida de desarrollo estructurado garantiza el éxito del proyecto de principio a fin. Dentro de Kinos Group, ofrecemos Kinos Advisory Services para ayudarlo a hacer avanzar su proyecto. Adoptamos la tecnología Torus Network Madtech: Marketing + Publicidad + Tecnología (Proptech, Retailtech, Legaltech, Insurtech, Fintech, Regtech, Wealthtech, Greentech), metodología progresiva y pronóstico en profundidad. Con actitud 100% colaborativa y compromiso con nuestros clientes, transformamos programas y ventas inmobiliarias.
          </h5>

          <h3>EXPERTOS EN COMERCIALIZACIÓN DE  PROYECTOS OBRA NUEVA</h3>
          <h5>Kinos aporta un enfoque moderno al marketing y las ventas de nuevos desarrollos.</h5>
          <h5>Desde conversiones de alquiler boutique hasta lujosos condominios desde cero, explore nuestra cartera de ofertas actuales. Kinos desde el año 2003 ha sido reconocida como una de las firmas inmobiliarias líderes en España y Portugal en lo que respecta a la comercialización, promoción y venta de promociones inmobiliarias de obra nueva y sobre plano, inmuebles comerciales y residenciales. Ventas de viviendas nuevas y residenciales Nuestro equipo de viviendas residenciales / nuevas asesora sobre oportunidades de compra y venta tanto a propietarios como a inversores que buscan adquirir apartamentos dentro de nuevos desarrollos residenciales en España.
          </h5>
          <h5>
            Realizamos trabajos de consultoría para los principales <Link to="/developers">constructores de viviendas</Link> y trabajamos con los <Link to="/investors">proveedores</Link> para establecer la estrategia de marketing y el paquete de precios más adecuados para desarrollos y unidades individuales. Un completo servicio de marketing "en línea y fuera de línea" para inversores, constructores y promotores inmobiliarios.
          </h5>

        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
