import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description}>
            Kinos es una de las firmas de asesoría inmobiliaria comercial y residencial líderes en España.
          </h2>
          <h3>ESTAMOS ORGULLOSOS DE NUESTRO ORIGEN ESPAÑOL</h3>
          <h5>
Brindamos una plataforma de servicios totalmente integrada a las empresas familiares,
             corporaciones nacionales y multinacionales e inversores institucionales en todo el mundo, así como a ocupantes, propietarios y desarrolladores
              de bienes inmuebles a nivel local, regional y nacional.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
